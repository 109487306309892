function addDaysToDate(dateString, days) {
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  if (!dateRegex.test(dateString)) {
    console.error('Wrong date format. The format is expected "dd-mm-yyyy".');
    return dateString;
  }

  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month, day);
  date.setDate(date.getDate() + days);

  const newDay = date.getDate();
  const newMonth = date.getMonth() + 1;
  const newYear = date.getFullYear();

  const formattedDay = newDay < 10 ? '0' + newDay : newDay.toString();
  const formattedMonth = newMonth < 10 ? '0' + newMonth : newMonth.toString();

  return `${newYear}-${formattedMonth}-${formattedDay}`;
}

export default addDaysToDate;
