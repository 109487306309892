import React, { useState, useEffect } from 'react';
import { Select, Space } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

dayjs.locale('en');

const { Option } = Select;

const DateSelector = ({ onChange, zone, onChanges }) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const handleDateChange = (date, dateType) => {
    if (dateType === 'year') {
      setSelectedYear(date, () => {});
    } else if (dateType === 'month') {
      setSelectedMonth(date, () => {});
    } else if (dateType === 'day') {
      setSelectedDay(date);
    }

    if (selectedYear !== null && selectedMonth !== null && selectedDay !== null) {
      const newDate = dayjs()
        .year(selectedYear)
        .month(selectedMonth - 1)
        .date(selectedDay);
      const formattedDate = newDate.format('YYYY-MM-DD');
      if (onChange) {
        onChange(formattedDate);
      }
    }
  };

  const getDropdownSequence = () => {
    const dropdownOrder = {
      DMY: ['Day', 'Month', 'Year'],
      MDY: ['Month', 'Day', 'Year']
    };
    return zone && zone in dropdownOrder ? dropdownOrder[zone] : ['Month', 'Day', 'Year'];
  };

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      const newDate = dayjs()
        .year(selectedYear)
        .month(selectedMonth - 1)
        .date(selectedDay);
      const formattedDate = newDate.format('YYYY-MM-DD');
      onChanges(formattedDate);
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  const dropdownSequence = getDropdownSequence();

  return (
    <Space>
      {dropdownSequence.map((dateType) => (
        <Select
          key={dateType}
          style={{ width: 100 }}
          value={
            dateType === 'Year' ? selectedYear : dateType === 'Month' ? selectedMonth : selectedDay
          }
          onChange={(value) => handleDateChange(value, dateType.toLowerCase())}
          placeholder={dateType}
          allowClear>
          {dateType === 'Year'
            ? Array.from({ length: 100 }, (_, i) => dayjs().year() - i).map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))
            : dateType === 'Month'
            ? Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                <Option key={month} value={month}>
                  {dayjs()
                    .month(month - 1)
                    .format('MMMM')}
                </Option>
              ))
            : Array.from(
                {
                  length:
                    selectedYear && selectedMonth
                      ? dayjs(`${selectedYear}-${selectedMonth}`).daysInMonth()
                      : 31
                },
                (_, i) => i + 1
              ).map((day) => (
                <Option key={day} value={day}>
                  {day}
                </Option>
              ))}
        </Select>
      ))}
    </Space>
  );
};

export default DateSelector;
