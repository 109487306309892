export const giveCabinsData = (data) => {
  const price = data?.pricing[0]?.prices;
  const rate_code = data?.pricing[0]?.rate_code;
  const grades = data?.ship?.grades;

  if (!price || !rate_code || !grades || !Array.isArray(price) || !Array.isArray(grades)) {
    return [];
  }

  const combinedCabins = price.map((cabin) => {
    if (!cabin || typeof cabin !== 'object') {
      return {};
    }

    const matchingGrade = grades.find((grade) => grade.code === cabin.grade);

    if (matchingGrade) {
      const combinedCabin = {
        ...cabin,
        ...matchingGrade,
        rate_code: rate_code
      };
      return combinedCabin;
    }

    return cabin;
  });

  return combinedCabins;
};
