import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { getOptions } from '../helpers/getOptions';
const opaquePaymentBaseQuery = fetchBaseQuery({
  baseUrl: getOptions('react_app_cabin_select_payment_domain'),
  prepareHeaders: async (headers) => {
    headers.set('Content-type', 'application/json; charset=UTF-8');
    headers.set('Authorization', `Bearer ${Cookies.get('token')}`);
    return headers;
  }
});

export const opaquePaymentApi = createApi({
  reducerPath: 'opaquePaymentApi',
  baseQuery: opaquePaymentBaseQuery,
  tagTypes: ['OpaquePayment'],
  endpoints: () => ({})
});

export const opaquePaymentAPI = opaquePaymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createOpaquePayment: builder.mutation({
      query: ({ session, pnr, source, transactions }) => ({
        url: '/opaque-payment/',
        body: {
          session,
          pnr,
          source,
          transactions,
          agency: getOptions('react_app_cabin_select_api_agency')
        },
        method: 'POST'
      }),
      invalidatesTags: ['Reservation']
    })
  }),
  overrideExisting: true
});

export const { useCreateOpaquePaymentMutation } = opaquePaymentAPI;
