import React, { useEffect } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import styles from './BookingConfirmationPage.module.css';
import SidebarCard from '../../components/SidebarCard';
import MapCard from '../../components/MapCard';
import SummaryInfo from '../../components/SummaryInfo';
import TwoColumnLayout from '../../components/TwoColumnLayout';
import BookingInfo from './BookingInfo';
import BookingPassengers from './BookingPassengers';
import BookingItinerary from './BookingItinerary';
import BookingPayment from './BookingPayment';

const { Title, Paragraph } = Typography;

const BookingConfirmationPage = () => {
  const banner = '../banner-placeholder.jpg';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={styles.banner} style={{ backgroundImage: `url("${banner}")` }}>
        <div className="container-xxl h-100 text-center d-flex justify-content-center align-items-center">
          <div style={{ maxWidth: '780px' }}>
            <Title className={'fs-1'}>Booking Confirmation</Title>
            <Paragraph className={'fs-5'}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna.
            </Paragraph>
          </div>
        </div>
      </div>

      <div className={'mt-5 mb-5'}>
        <TwoColumnLayout
          fixed={false}
          firstColumnContent={
            <Space size={[30, 30]} direction={'vertical'} className={'w-100'}>
              <BookingInfo />
              <BookingPassengers />
              <BookingItinerary />
              <BookingPayment />
            </Space>
          }
          secondColumnContent={
            <Row gutter={[30, 30]}>
              <Col flex="1 1 300px">
                <MapCard img={'/'} />
              </Col>
              <Col flex="1 1 300px">
                <SidebarCard title={'Itinerary'}>
                  <SummaryInfo dateInfo={true} portsInfo={true} stateroomInfo={true} />
                </SidebarCard>
              </Col>
            </Row>
          }
        />
      </div>
    </>
  );
};

export default BookingConfirmationPage;
