import { Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useSession';
import { useEffect } from 'react';
import { getOptions } from '../../helpers/getOptions';

const Layout = () => {
  const { logout, login } = useAuth();

  const urlParams = new URLSearchParams(window.location.search);
  const agencyParam = urlParams.get('agency');
  const agencyCookie = getOptions('react_app_cabin_select_api_agency');
  useEffect(() => {
    if (agencyCookie !== agencyParam) {
      console.log('Not a standard agency');
      logout();
      login();
    }
  }, []);
  return (
    <div className={'.wrapper'}>
      <header>
        <img
          src="https://rccldemo.cabinselect.com/wp-content/uploads/sites/7/2023/08/cabin-select-logo.webp"
          alt="Cabin Select"
          style={{ width: 300 }}
        />
      </header>

      <Outlet />

      <footer style={{ height: '150px', backgroundColor: '#eee' }}>
        <div className="container">{/*&copy; 2023 by Cabin Select*/}</div>
      </footer>
    </div>
  );
};

export default Layout;
