export function removeDuplicates(arr) {
  let new_arr = [];
  let lookup = {};

  for (let i in arr) {
    lookup[arr[i].value] = arr[i];
  }

  for (let i in lookup) {
    new_arr.push(lookup[i]);
  }

  return new_arr;
}
