import React from 'react';

const PhoneInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.3636 24.5455H2.72727V5.45455H16.3636M16.3636 0H2.72727C1.21364 0 0 1.21364 0 2.72727V27.2727C0 27.996 0.287337 28.6897 0.7988 29.2012C1.31026 29.7127 2.00396 30 2.72727 30H16.3636C17.087 30 17.7806 29.7127 18.2921 29.2012C18.8036 28.6897 19.0909 27.996 19.0909 27.2727V2.72727C19.0909 1.21364 17.8636 0 16.3636 0Z"
        fill="white"
      />
    </svg>
  </div>
);

export default PhoneInputIcon;
