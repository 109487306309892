export function showCurrency(currencyCode, value, hidePP, roundToInteger = false) {
  const sPP = hidePP ? '' : ' pp';

  if (currencyCode && value !== 'N/A' && value) {
    let formattedValue = value;

    if (roundToInteger) {
      formattedValue = Math.floor(value);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: roundToInteger ? 0 : 2
    });

    return formatter.format(formattedValue) + sPP;
  } else {
    return ' N/A';
  }
}
