/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Card, Button } from 'antd';
import UserIcon from './Icons/UserIcon';
import UserInputIcon from './Icons/UserInputIcon';
import NationalityInputIcon from './Icons/NationalityInputIcon';
import MarkerInputIcon from './Icons/MarkerInputIcon';
import PhoneInputIcon from './Icons/PhoneInputIcon';
import EmailInputIcon from './Icons/EmailInputIcon';
import ArrowDownSelectIcon from './Icons/ArrowDownSelectIcon';
import DateInputIcon from './Icons/DateInputIcon';
import countryList from '../countries.data';
import DateSelector from './UI/DateSelector';
import { getOptions } from '../helpers/getOptions';
import useStore from '../store/userStore';
import labelList from '../nationalities.data';

const { Option } = Select;
const PassengerInformationComponent = ({
  index,
  openIndex,
  setOpenIndex,
  allPass,
  forms,
  setForms,
  nextPassenger,
  initialValue
}) => {
  const [formData, setFormData] = useState({});
  const isLastPassenger = index === allPass - 1;
  const buttonLabel = isLastPassenger ? 'Continue' : 'Next Passenger';
  const [form] = Form.useForm();
  const { setLeadGuestData, leadGuest } = useStore();
  const handleFormChange = (allValues) => {
    setFormData((prev) => ({
      ...prev,
      ...allValues
    }));
  };

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    forms[index] = form;
    setForms([...forms]);
  }, []);

  const onFinish = (values) => {
    nextPassenger();
  };

  const handleButtonClick = (index) => {
    setOpenIndex(index);
  };

  const dobHandler = (value) => {
    form.setFieldsValue({ dob: value });
  };

  return (
    <Card className="mb-4" bordered={false} style={{ padding: 0 }}>
      <div className="guest-header" onClick={() => handleButtonClick(index)}>
        <div className="d-flex justify-content-start gap-3">
          <UserIcon />
          <div className="new-design-guest">
            {index === 0 && 'Primary '}
            Guest (Adult {index + 1})
          </div>
        </div>
      </div>
      <div className="traveler-form mt-4">
        <div className={openIndex !== index ? 'd-none' : ''}>
          <div className="h1 mb-4">Please enter your details</div>
          <Form
            form={form}
            name={`passenger-form-${index}`}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={handleFormChange}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect new-design-title">
                  <UserInputIcon />
                  <Form.Item
                    name="title"
                    label={<div className="customFieldLabel">Title</div>}
                    rules={[{ required: true, message: 'Please select a title' }]}
                    className="customFieldSelectItem">
                    <Select
                      allowClear
                      placeholder="Select a title"
                      className={'customFieldSelectInput'}
                      bordered={false}
                      suffixIcon={<ArrowDownSelectIcon />}>
                      <Option value="Mr">Mr</Option>
                      <Option value="Mrs">Mrs</Option>
                      <Option value="Ms">Ms</Option>
                      <Option value="Miss">Miss</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect new-design-gender">
                  <UserInputIcon />
                  <Form.Item
                    name="gender"
                    label={<div className="customFieldLabel">Gender</div>}
                    rules={[{ required: true, message: 'Please select your gender' }]}
                    className="customFieldSelectItem">
                    <Select
                      placeholder="Select a gender"
                      bordered={false}
                      className={'customFieldSelectInput'}
                      suffixIcon={<ArrowDownSelectIcon />}>
                      <Option value="M">Male</Option>
                      <Option value="F">Female</Option>
                      <Option value="O">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  label={<div className="customFieldLabel">First Name</div>}
                  rules={[{ required: true, message: 'Please enter your first name' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="John"
                    prefix={<UserInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="surname"
                  label={<div className="customFieldLabel">Surname</div>}
                  rules={[{ required: true, message: 'Please enter your surname' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="Smith"
                    prefix={<UserInputIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect">
                  <DateInputIcon />
                  <Form.Item
                    name="dob"
                    label={<div className="customFieldLabel">Date of Birth</div>}
                    rules={[{ required: true, message: 'Please select your date of birth' }]}
                    className="customFieldSelectItem">
                    <DateSelector
                      value={formData.dob}
                      onChange={(date) => form.setFieldsValue({ dob: date })}
                      onChanges={dobHandler}
                      zone={getOptions('react_app_cabin_select_date_format')}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect new-design-nationality">
                  <NationalityInputIcon />
                  <Form.Item
                    name="nationality"
                    label={<div className="customFieldLabel">Citizenship</div>}
                    rules={[{ required: true, message: 'Choose Citizenship' }]}
                    className={'customFieldSelectItem'}>
                    <Select
                      showSearch
                      placeholder="Choose Citizenship"
                      bordered={false}
                      className={'customFieldSelectInput'}
                      suffixIcon={<ArrowDownSelectIcon />}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={labelList}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="city"
                  label={<div className="customFieldLabel">City</div>}
                  rules={[{ required: false, message: 'Please enter your city' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="Boston"
                    defaultValue={leadGuest && leadGuest.city && leadGuest.city}
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="state"
                  label={<div className="customFieldLabel">State</div>}
                  rules={[{ required: false, message: 'Please enter your state' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="State"
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="zipCode"
                  label={<div className="customFieldLabel">Zip Code</div>}
                  rules={[{ required: false, message: 'Please enter your zip code' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="Zip Code"
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect new-design-country">
                  <MarkerInputIcon />
                  <Form.Item
                    name="country"
                    label={<div className="customFieldLabel">Country</div>}
                    rules={[{ required: true, message: 'Please select your country' }]}
                    className={'customFieldSelectItem'}>
                    <Select
                      showSearch
                      placeholder="Select a country"
                      bordered={false}
                      className={'customFieldSelectInput'}
                      suffixIcon={<ArrowDownSelectIcon />}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={countryList}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="email"
                  label={<div className="customFieldLabel">Email</div>}
                  rules={[
                    {
                      required: false,
                      type: 'email',
                      message: 'Please enter a valid email'
                    }
                  ]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="Email"
                    prefix={<EmailInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="phone"
                  label={<div className="customFieldLabel">Phone</div>}
                  rules={[{ required: false, message: 'Please enter your phone number' }]}>
                  <Input
                    bordered={false}
                    className="input"
                    placeholder="Phone"
                    prefix={<PhoneInputIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div className="text-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="border-radius-0 fs-5"
                  style={{ height: 'auto' }}>
                  {buttonLabel}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(PassengerInformationComponent);
