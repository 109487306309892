import React from 'react';
import { Typography, Card, List } from 'antd';
import useStore from '../../../store/userStore';
import { showCurrency } from '../../../helpers/showCurrency';

const { Title, Text } = Typography;
const { Item } = List;

const BookingPayment = () => {
  const { pricingData, guests, paymentType, currency } = useStore();

  const price = pricingData && guests ? (pricingData * guests).toFixed(2) : 0;
  const depositPrice = price * 0.1;
  // const totalPrice = price - depositPrice;

  return (
    <Card className={'shadow'}>
      <Title level={2} className={'fs-2 fw-bold'}>
        Payment (dev)
      </Title>
      <List itemLayout={'vertical'}>
        {paymentType === 'deposit' && (
          <Item className={'pe-0 ps-0 d-flex flex-wrap align-items-baseline  gap-2'}>
            <Text className={'fs-3 fw-bold'}>Deposit paid</Text>
            <Text className={'fs-3 fw-bold'}>{showCurrency(currency, depositPrice)}</Text>
          </Item>
        )}
        <Item className={'pe-0 ps-0 d-flex flex-wrap align-items-baseline  gap-2'}>
          <Text className={'fs-2 fw-bold'}>Total Booking Price</Text>
          <Text className={'fs-2 fw-bold'}>{showCurrency(currency, price)}</Text>
        </Item>
      </List>
    </Card>
  );
};

export default BookingPayment;
