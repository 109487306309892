import React from 'react';
import { List, Typography } from 'antd';

const StateroomTypeCardDetails = ({ data }) => {
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
      itemLayout="horizontal"
      bordered={false}
      dataSource={data}
      renderItem={(item) => (
        <List.Item className={'p-0'}>
          <List.Item.Meta
            title={
              <Typography.Text strong className={'fs-6'}>
                {item.title}
              </Typography.Text>
            }
            description={item.des}
          />
        </List.Item>
      )}
    />
  );
};

export default React.memo(StateroomTypeCardDetails);
