import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Alert, Image, message } from 'antd';
import useStore from '../../store/userStore';
import { extractCabins } from '../../helpers/extractCabins';
import { showCurrency } from '../../helpers/showCurrency';
import { fallback } from '../../helpers/fallback';
import { filterObjectsByKeyValue } from '../../helpers/filterObjectsByKeyValue';
import { calcPrice } from '../../helpers/calcPrice';
import ImageCards from './ImageCards';
export const StateroomsTypeChoosing = ({ allPrices, grades }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const info = () => {
    messageApi.open({
      type: 'error',
      content: 'Sorry, there are no cabins available',
      duration: 5
    });
  };
  const [selectedCard, setSelectedCard] = useState(null);
  const {
    setPricingData,
    setTypeCabin,
    typeCabin,
    guests,
    pricingData,
    setCurrentGrade,
    currentGrade,
    setCurrency,
    currency,
    setCabinDataStore,
    adults,
    children,
    infants,
    setContinue
  } = useStore();
  const [allCards, setCards] = useState([]);

  function processCabins(cabins, passengerCount) {
    const cabinTypes = [
      { name: 'inside', grade: 'A0', price: '3800' },
      { name: 'outside', grade: 'A1', price: '3800' },
      { name: 'balcony', grade: 'N/A', price: 'N/A' },
      { name: 'suite', grade: 'A3', price: '3800' }
    ];

    const cabinTypeMapping = {
      1: ['single'],
      2: ['standard'],
      3: ['third', 'extra'],
      4: ['fourth', 'extra']
    };

    const result = [];

    for (const cabinType of cabinTypes) {
      const cabinsOfType = cabins.filter((cabin) => cabin.meta_category === cabinType.name);
      const helper = [];

      cabinsOfType.forEach((cabin) => {
        const keysToCheck = cabinTypeMapping[passengerCount];
        const hasAvailableCabin = keysToCheck.some((key) => cabin[key] !== '0.00');
        if (hasAvailableCabin) {
          helper.push(cabin);
        }
      });

      if (helper.length === 0) {
        result.push({ ...cabinType, grade: 'N/A', price: 'N/A', image: '/' });
      } else if (helper.length === 1) {
        const selectedCabin = helper[0];
        const image =
          selectedCabin.images && selectedCabin.images[0] ? selectedCabin.images[0] : '/';
        result.push({
          ...cabinType,
          grade: selectedCabin.grade,
          price: selectedCabin[cabinTypeMapping[passengerCount][0]],
          image
        });
      } else {
        const lowestPriceCabin = helper.reduce((prev, curr) => {
          const keysToCheck = cabinTypeMapping[passengerCount];
          const prevPrice = parseFloat(prev[keysToCheck[0]]);
          const currPrice = parseFloat(curr[keysToCheck[0]]);
          return currPrice <= prevPrice ? curr : prev;
        });

        const image =
          lowestPriceCabin.images && lowestPriceCabin.images[0] ? lowestPriceCabin.images[0] : '/';
        result.push({
          ...cabinType,
          grade: lowestPriceCabin.grade,
          price: lowestPriceCabin[cabinTypeMapping[passengerCount][0]],
          image
        });
      }
    }

    return result;
  }

  const allCabins = extractCabins(allPrices, grades);

  const selectedHandler = (name, price, grade) => {
    setSelectedCard(grade);
    setTypeCabin(name);
  };

  const handleCardClick = (name, price, grade) => {
    if (price === 'N/A') {
      return;
    }
    selectedHandler(name, price, grade);
  };

  useEffect(() => {
    const c = processCabins(allCabins, guests);
    setCards(c);
  }, [guests]);

  function getCheapestCard(cards) {
    let cheapestCard = null;

    for (const card of cards) {
      const price = parseFloat(card.price);
      if (
        !isNaN(price) &&
        price !== 0 &&
        (cheapestCard === null || price < parseFloat(cheapestCard.price))
      ) {
        cheapestCard = card;
      }
    }

    return cheapestCard;
  }

  useEffect(() => {
    setCabinDataStore({});
    if (typeCabin) {
      if (typeCabin !== getCheapestCard(processCabins(allCabins, guests), allCabins)?.name) {
        let cabins;
        processCabins(allCabins, guests).map((item) => {
          if (item.name === typeCabin) {
            cabins = item;
          }
        });
        setSelectedCard(cabins);
        setTypeCabin(cabins.name);
      } else {
        setSelectedCard(getCheapestCard(processCabins(allCabins, guests), allCabins));
        setTypeCabin(getCheapestCard(processCabins(allCabins, guests), allCabins).name);
      }
    } else {
      setSelectedCard(getCheapestCard(processCabins(allCabins, guests), allCabins));
      setTypeCabin(getCheapestCard(processCabins(allCabins, guests), allCabins).name);
    }
  }, [guests]);

  useEffect(() => {
    setContinue(true);
    if (selectedCard) {
      if (selectedCard.price === 'N/A') {
        let cabin;
        allCards.map((item) => {
          if (item.price !== 'N/A') {
            cabin = item;
          }
        });
        setSelectedCard(cabin);
        if (cabin?.name) {
          setTypeCabin(cabin.name);
        } else {
          setContinue(false);
          info();
        }
      }
    }
  }, [selectedCard]);

  const calculatePrice = (price) => {
    if (guests === 1 || guests === 2) {
      return price;
    }
    if (guests === 3) {
      let total = allCabins.reduce((accumulator, item) => {
        if (selectedCard.grade === item.grade) {
          const standard = item.standard;
          const third = parseFloat(item.third);
          const result = (standard * 2 + third) / 3;
          return accumulator + result;
        }
        return accumulator;
      }, 0);
      // setPricingData(total);
      return total;
    }

    if (guests === 4) {
      if (price !== 'N/A') {
        let total = 0;
        let count = 0;
        let g;

        allCabins.forEach((item) => {
          if (selectedCard.grade === item.grade) {
            g = item;
            const standard = item.standard;
            const fourth = parseFloat(item.fourth);
            const result = (standard * 3 + fourth) / 4;

            total += result;
            count++;
          }
        });

        if (g && currentGrade !== g) {
          setCurrentGrade(() => g);
        }

        const averagePrice = total / count;
        const roundedPrice = averagePrice;
        return roundedPrice.toString();
      } else {
        return price;
      }
    }
  };

  useEffect(() => {
    if (selectedCard?.price) {
      const price = selectedCard.price;
      if (price !== pricingData) {
        const obj = filterObjectsByKeyValue(allCabins, 'grade', selectedCard?.grade);
        obj && obj.length && setPricingData(calcPrice(obj[0], guests, adults, children, infants));
      }
    }
  }, [selectedCard]);

  useEffect(() => {
    setCurrency(allCabins[0].currency);
  }, []);

  const getImg = (img, allCab) => {
    if (img === '/') {
      let c = [];
      const cabinsWithImages = Object.values(allCab).filter(
        (cabin) => cabin.images && cabin.images.length > 0
      );
      if (cabinsWithImages.length > 0) {
        c.push(cabinsWithImages[0].images[0]);
        return c;
      }
    } else {
      return img;
    }
  };

  return (
    <>
      {contextHolder}
      <div className="mv-24 p-12" style={{ backgroundColor: '#E0E0E0' }}>
        <h2>Choose: Stateroom 1 type</h2>
        <Row gutter={[16, 16]}>
          {allCards.length > 0 &&
            allCards.map((card, key) => {
              return (
                <Col key={key} xs={24} sm={12} md={6}>
                  <Card
                    style={{ borderRadius: 0, padding: 0 }}
                    className="p-0 card-border cabins-type-card"
                    onClick={() =>
                      handleCardClick(card.name, card.price ? card.price : 'N/A', card)
                    }
                    hoverable={card.price ? card.price : 'N/A'}
                    cover={
                      card?.image && card.image ? (
                        <ImageCards
                          alt={card.name}
                          img={getImg(card.image, allCabins)}
                          height={220}
                          className="card-border"
                          preview={false}
                          fallback={fallback()}
                        />
                      ) : (
                        <Image
                          alt={card.name}
                          src={'/'}
                          style={{ borderRadius: 0 }}
                          height={220}
                          className="card-border"
                          preview={false}
                          fallback={fallback()}
                        />
                      )
                    }>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 0,
                        border: 'none'
                      }}>
                      <div style={{ textTransform: 'capitalize' }}>{card.name}</div>
                      <p className={card.price >= 1 ? 'price-pp' : null}>
                        {card.price >= 1
                          ? showCurrency(currency, calculatePrice(card.price))
                          : 'N/A'}
                      </p>
                    </div>
                  </Card>
                  {selectedCard?.name === card.name && (
                    <Alert
                      message="Selected"
                      type="success"
                      style={{
                        borderRadius: 0,
                        backgroundColor: 'black',
                        color: 'white',
                        fontWeight: 'bolder',
                        textAlign: 'center',
                        zIndex: 46
                      }}
                      className="card-border"
                    />
                  )}
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};
