import React from 'react';

const MarkerInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15 27C11.8174 27 8.76516 25.7357 6.51472 23.4853C4.26428 21.2348 3 18.1826 3 15C3 11.8174 4.26428 8.76516 6.51472 6.51472C8.76516 4.26428 11.8174 3 15 3C18.1826 3 21.2348 4.26428 23.4853 6.51472C25.7357 8.76516 27 11.8174 27 15C27 18.1826 25.7357 21.2348 23.4853 23.4853C21.2348 25.7357 18.1826 27 15 27ZM15 0C13.0302 0 11.0796 0.387987 9.25975 1.14181C7.43986 1.89563 5.78628 3.00052 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C5.78628 26.9995 7.43986 28.1044 9.25975 28.8582C11.0796 29.612 13.0302 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387987 16.9698 0 15 0ZM15 15.75C14.4033 15.75 13.831 15.5129 13.409 15.091C12.9871 14.669 12.75 14.0967 12.75 13.5C12.75 12.9033 12.9871 12.331 13.409 11.909C13.831 11.4871 14.4033 11.25 15 11.25C15.5967 11.25 16.169 11.4871 16.591 11.909C17.0129 12.331 17.25 12.9033 17.25 13.5C17.25 14.0967 17.0129 14.669 16.591 15.091C16.169 15.5129 15.5967 15.75 15 15.75ZM15 7.8C11.85 7.8 9.3 10.35 9.3 13.5C9.3 18 15 23.25 15 23.25C15 23.25 20.7 18 20.7 13.5C20.7 10.35 18.15 7.8 15 7.8Z"
        fill="white"
      />
    </svg>
  </div>
);

export default MarkerInputIcon;
