/* eslint-disable react/prop-types */
import React from 'react';
import '../../App.css';
import { Carousel, Modal } from 'antd';

const CarouselModal = ({ open, onOk, onCancel, images }) => {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel}>
      <Carousel autoplay>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`image ${index}`} />
        ))}
      </Carousel>
    </Modal>
  );
};

export default CarouselModal;
