/* eslint-disable no-unused-vars */
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Col, Row, Space, Spin, message } from 'antd';
import { useCruiseDetailQuery } from '../../services/SearchService';
import PassengerInformationComponent from '../../components/PassengerInformationComponent';
import { PaymentComponent } from '../../components/PaymentComponent';
import { useCreateReservationMutation } from '../../services/ReservationService';
import Cookies from 'js-cookie';
import { FormContinue } from '../stateroom/FormContinue';
import TwoColumnLayout from '../../components/TwoColumnLayout';
import SummaryInfo from '../../components/SummaryInfo';
import SidebarCard from '../../components/SidebarCard';
import userStore from '../../store/userStore';
import { formatObjData } from '../../helpers/formatObjData';
import { useGetPricingQuery } from '../../services/PricingService';
import { getOptions } from '../../helpers/getOptions';
import { useNavigate } from 'react-router-dom';

const BasketPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const sessionKey = Cookies.get('sessionKey');
  const [reservationConfirmed, setReservationConfirmed] = useState(false);
  const [createReservations] = useCreateReservationMutation();
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());
  const {
    setPassengersData,
    guests,
    cabinData,
    passengersData,
    cabinsNumber,
    setPNR,
    setReservationsData,
    setPricingData,
    setAllPriceData,
    setLeadGuestData,
    leadGuest
  } = userStore();

  const { data } = useCruiseDetailQuery({
    session: sessionKey,
    sailing_code: searchParams.ship,
    source: searchParams.source
  });

  const [openIndex, setOpenIndex] = useState(0);
  const [basketStep, setBasketStep] = useState(1);
  const [forms, setForms] = useState(new Array(guests));
  const [paymentForm, setPaymentForm] = useState(null);

  const nextPassenger = () => {
    if (openIndex === 0) {
      const leadPassanger = forms[0].getFieldsValue();
      setLeadGuestData(leadPassanger);
    }
    if (openIndex < guests - 1) {
      setOpenIndex(openIndex + 1);
    }

    if (openIndex === guests - 1) {
      continueHandler();
    }
  };

  const checkAllPassengerForms = async () => {
    const passengersData = [];
    let error = false;
    try {
      await Promise.allSettled(
        forms.map((formItem, index) =>
          formItem
            .validateFields()
            .then((values) => {
              passengersData.push(values);
            })
            .catch(() => {
              if (!error) {
                setOpenIndex(index);
              }
              error = true;
            })
        )
      );
    } catch (error) {
      console.error(error);
    }

    if (!error) {
      setPassengersData(passengersData);
      setBasketStep(2);
    }
  };

  const checkPaymentForm = () => {
    paymentForm
      .validateFields()
      .then(() => {
        paymentForm.submit();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const continueHandler = () => {
    if (basketStep === 1) {
      checkAllPassengerForms();
    } else if (basketStep === 2) {
      checkPaymentForm();
    }
  };

  const redirectTimeout = setTimeout(() => {
    if (!data) {
      navigate(`/stateroom?code=${searchParams.ship}&source=${searchParams.source}`, {
        state: {
          code: searchParams.ship
        }
      });
    }
  }, 1500);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (basketStep === 2) {
      createReservationsHelper();
    }

    return () => clearTimeout(redirectTimeout);
  }, [basketStep, data]);

  const createReservationsHelper = async () => {
    const dining_seating = '';
    const dining_table = '';
    const g = passengersData.map((item, index) => {
      const doneObg = {
        lead_passenger: index === 0 ? true : false,
        title: item?.title,
        given_name: item?.firstName,
        last_name: item?.surname,
        lastname: item?.surname,
        date_of_birth: formatObjData(item?.dob),
        address: ['adress1', 'adress2'],
        city: item?.city,
        state: item?.state,
        zip_code: item?.zipCode,
        country: item?.country,
        phone: item?.phone,
        email: item?.email,
        nationality: item?.nationality,
        language: item?.country,
        request: ''
      };
      return doneObg;
    });
    const guests = g;
    try {
      const { data } = await createReservations({
        sailing_code: searchParams.ship,
        source: searchParams.source,
        session: sessionKey,
        language: getOptions('react_app_cabin_select_api_language'),
        rate_code: searchParams.rate_code,
        promotions: [],
        cabins: [
          {
            grade_code: cabinData?.grade,
            cabin_number: cabinsNumber?.number,
            dining_seating,
            dining_table,
            guests
          }
        ]
      })
        .then((data) => {
          setReservationConfirmed(true);
          setPNR(data.data.pnr);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });

      if (data.error || !data.data) {
        console.log('error', data);
      } else if (!data.error && data.data) {
        setReservationsData(data.data);
        setPNR(data.data.pnr);
      }
    } catch (error) {
      console.error(error);
      error(error);
    }
  };

  const guestDobParams = passengersData.map((passenger, index) => {
    const formattedDate = formatObjData(passenger.dob);
    return { [`guest_dob_${index + 1}`]: formattedDate };
  });

  const {
    isLoading,
    isError,
    data: getPricing,
    error,
    refetch: refetchPricing
  } = useGetPricingQuery({
    sailing_code: searchParams.ship,
    source: searchParams.source,
    session: sessionKey,
    language: getOptions('react_app_cabin_select_api_language'),
    rate_code: searchParams.rate_code,
    grade_code: cabinData?.grade,
    cabin_number: cabinsNumber?.number,
    guest_dob: guestDobParams,
    guest_count: searchParams.passenger
  });

  const getPricingHandle = () => {
    refetchPricing({
      sailing_code: searchParams.ship,
      source: searchParams.source,
      session: sessionKey,
      language: getOptions('react_app_cabin_select_api_language'),
      rate_code: searchParams.rate_code,
      grade_code: cabinData?.grade,
      cabin_number: cabinsNumber?.number,
      guest_dob: guestDobParams,
      guest_count: searchParams.passenger
    });
  };

  useEffect(() => {
    if (!isLoading) {
      if (getPricing?.total_price) {
        setPricingData(getPricing?.total_price);
        setAllPriceData(getPricing);
      }
      isError && clearTimeout(redirectTimeout);
    }
  }, [isLoading]);

  useEffect(() => {
    if (basketStep === 2) {
      getPricingHandle();
    }
  }, [basketStep]);

  const errorHending = (text) => {
    messageApi.open({
      type: 'error',
      content: text
    });
  };

  return (
    data && (
      <div style={{ paddingTop: 18 }}>
        {contextHolder}
        <TwoColumnLayout
          firstColumnContent={
            <Space size={[30, 30]} direction={'vertical'} className={'w-100'}>
              {basketStep === 1 && (
                <>
                  <h1 style={{ marginBottom: 0 }}>Who’s traveling?</h1>
                  {[...Array(guests)].map((_, index, original) => (
                    <PassengerInformationComponent
                      key={index}
                      index={index}
                      openIndex={+openIndex}
                      setOpenIndex={setOpenIndex}
                      allPass={original.length}
                      forms={forms}
                      setForms={setForms}
                      nextPassenger={nextPassenger}
                      initialValue={
                        index === 0 && leadGuest
                          ? leadGuest
                          : {
                              city: leadGuest?.city,
                              country: leadGuest?.country,
                              zipCode: leadGuest?.zipCode,
                              state: leadGuest?.state,
                              email: leadGuest?.email,
                              phone: leadGuest?.phone
                            }
                      }
                    />
                  ))}
                </>
              )}

              {basketStep === 2 && (
                <>
                  {reservationConfirmed ? (
                    <>
                      <PaymentComponent
                        passengersData={passengersData}
                        setPaymentForm={setPaymentForm}
                        setBasketStep={setBasketStep}
                      />
                    </>
                  ) : (
                    <Spin tip="Reservation" size="large">
                      <div className="content" />
                    </Spin>
                  )}
                </>
              )}
            </Space>
          }
          secondColumnContent={
            <Row gutter={[30, 30]}>
              <Col flex="1 1 300px">
                <SidebarCard title={'Itinerary'}>
                  <SummaryInfo
                    dateInfo={true}
                    portsInfo={true}
                    stateroomInfo={true}
                    priceInfo={true}
                  />
                </SidebarCard>
              </Col>
            </Row>
          }
        />
        <FormContinue nextStep={() => {}} step={5} goToChooseCabin={continueHandler} />
      </div>
    )
  );
};

export { BasketPage };
