import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/AuthSlice';
import { authAPI } from '../services/AuthService';
import { sessionAPI } from '../services/SessionService';
import { apiSearch } from '../services/apiSearch';
import { opaquePaymentApi } from '../services/opaquePaymentApi';

export const setupStore = () => {
  return configureStore({
    reducer: {
      authReducer,
      [authAPI.reducerPath]: authAPI.reducer,
      [sessionAPI.reducerPath]: sessionAPI.reducer,
      [apiSearch.reducerPath]: apiSearch.reducer,
      [opaquePaymentApi.reducerPath]: opaquePaymentApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        authAPI.middleware,
        sessionAPI.middleware,
        apiSearch.middleware,
        opaquePaymentApi.middleware
      ])
  });
};
