import React from 'react';
import { Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { Text, Paragraph } = Typography;

const StateroomTypes = ({ type, price }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  return (
    <div className={'p-2 flex-grow-1'}>
      <Paragraph className={`${!isMobile && 'fs-5'} mb-0`}>
        <span className={'text-uppercase'}>{type}</span>
        {isMobile ? <br /> : ' '}
        from
      </Paragraph>

      <Text className={`${isMobile ? 'fs-5' : 'fs-1'} fw-bold`}>{price}</Text>
    </div>
  );
};

export default StateroomTypes;
