function getCheapestStandardTypeCabin(markets) {
  if (markets) {
    let cheapestType = { price: null, name: null };

    for (const marketsKey in markets) {
      const price = parseFloat(markets[marketsKey].price);

      if (price) {
        cheapestType =
          !cheapestType.price || price < cheapestType.price
            ? { price, name: marketsKey }
            : cheapestType;
      }
    }

    return cheapestType.name;
  } else {
    return null;
  }
}

export default getCheapestStandardTypeCabin;
