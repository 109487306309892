import React from 'react';

const NationalityInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1 0C1.26522 0 1.51957 0.105357 1.70711 0.292893C1.89464 0.48043 2 0.734784 2 1V1.88C3.06 1.44 4.5 1 6 1C9 1 9 3 11 3C14 3 15 1 15 1V9C15 9 14 11 11 11C8 11 8 9 6 9C3 9 2 11 2 11V18H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM2 4.25V8.5C2 8.5 4 7 6 7C8 7 9 9 11 9C13 9 13 8 13 8V4.5C13 4.5 12 5 11 5C9 5 8 3 6 3C4 3 2 4.25 2 4.25Z"
        fill="white"
      />
    </svg>
  </div>
);

export default NationalityInputIcon;
