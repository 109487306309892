import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { getOptions } from '../helpers/getOptions';
const baseQuery = fetchBaseQuery({
  baseUrl: getOptions('react_app_cabin_select_search_domain'),
  prepareHeaders: async (headers) => {
    headers.set('Content-type', 'application/json; charset=UTF-8');
    headers.set('Authorization', `Bearer ${Cookies.get('token')}`);
    return headers;
  }
});

export const apiSearch = createApi({
  reducerPath: 'apiSearch',
  baseQuery: baseQuery,
  tagTypes: ['Counter', 'HoldCabin', 'Pricing', 'Search', 'CruiseDetail', 'Reservation'],
  endpoints: () => ({})
});
