import React from 'react';
import { Typography, Card } from 'antd';
import userStore from '../../../store/userStore';

const { Title, Text } = Typography;

const BookingInfo = () => {
  const { shipName } = userStore();

  return (
    <Card className={'shadow'}>
      <Title level={2} className={'fs-2 fw-normal'}>
        Welcome aboard {shipName}
      </Title>
      <Text>
        <strong>On the MS Viva Tiara...</strong> Lorem ipsum dolor sit amet, consectetur adipiscing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Text>
      <Title level={2} className={'fs-2 fw-bold'}>
        What to expect next:
      </Title>
      <Text className={'fs-4'}>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
        laudantium.
      </Text>
    </Card>
  );
};

export default BookingInfo;
