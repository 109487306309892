import React from 'react';

const CabinPlaceholder = (props) => (
  <svg
    width="282"
    height="160"
    viewBox="0 0 282 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="282" height="159.394" fill="#747474" />
    <path
      d="M97.9033 89.8743C112.211 89.8743 123.761 80.7828 123.761 69.5202C123.761 58.2576 112.211 49.166 97.9033 49.166C83.5954 49.166 72.0456 58.2576 72.0456 69.5202C72.0456 80.7828 83.5954 89.8743 97.9033 89.8743ZM97.9033 62.7355C102.644 62.7355 106.522 65.7886 106.522 69.5202C106.522 73.2518 102.644 76.3049 97.9033 76.3049C93.1627 76.3049 89.284 73.2518 89.284 69.5202C89.284 65.7886 93.1627 62.7355 97.9033 62.7355ZM201.334 42.3813H132.38V96.6591H63.4264V28.8119H46.188V130.583H63.4264V110.228H218.572V130.583H235.811V69.5202C235.811 54.526 220.382 42.3813 201.334 42.3813ZM218.572 96.6591H149.619V55.9508H201.334C210.815 55.9508 218.572 62.057 218.572 69.5202V96.6591Z"
      fill="white"
    />
  </svg>
);

export default CabinPlaceholder;
