const labelList = [
  {
    value: 'AFG',
    label: 'Afghan'
  },
  {
    value: 'ALA',
    label: 'Åland Island'
  },
  {
    value: 'ALB',
    label: 'Albanian'
  },
  {
    value: 'DZA',
    label: 'Algerian'
  },
  {
    value: 'ASM',
    label: 'American Samoan'
  },
  {
    value: 'AND',
    label: 'Andorran'
  },
  {
    value: 'AGO',
    label: 'Angolan'
  },
  {
    value: 'AIA',
    label: 'Anguillan'
  },
  {
    value: 'ATA',
    label: 'Antarctic'
  },
  {
    value: 'ATG',
    label: 'Antiguan or Barbudan'
  },
  {
    value: 'ARG',
    label: 'Argentine'
  },
  {
    value: 'ARM',
    label: 'Armenian'
  },
  {
    value: 'ABW',
    label: 'Aruban'
  },
  {
    value: 'AUS',
    label: 'Australian'
  },
  {
    value: 'AUT',
    label: 'Austrian'
  },
  {
    value: 'AZE',
    label: 'Azerbaijani, Azeri'
  },
  {
    value: 'BHS',
    label: 'Bahamian'
  },
  {
    value: 'BHR',
    label: 'Bahraini'
  },
  {
    value: 'BGD',
    label: 'Bangladeshi'
  },
  {
    value: 'BRB',
    label: 'Barbadian'
  },
  {
    value: 'BLR',
    label: 'Belarusian'
  },
  {
    value: 'BEL',
    label: 'Belgian'
  },
  {
    value: 'BLZ',
    label: 'Belizean'
  },
  {
    value: 'BEN',
    label: 'Beninese, Beninois'
  },
  {
    value: 'BMU',
    label: 'Bermudian, Bermudan'
  },
  {
    value: 'BTN',
    label: 'Bhutanese'
  },
  {
    value: 'BOL',
    label: 'Bolivian'
  },
  {
    value: 'BES',
    label: 'Bonaire'
  },
  {
    value: 'BIH',
    label: 'Bosnian or Herzegovinian'
  },
  {
    value: 'BWA',
    label: 'Motswana, Botswanan'
  },
  {
    value: 'BVT',
    label: 'Bouvet Island'
  },
  {
    value: 'BRA',
    label: 'Brazilian'
  },
  {
    value: 'IOT',
    label: 'BIOT'
  },
  {
    value: 'BRN',
    label: 'Bruneian'
  },
  {
    value: 'BGR',
    label: 'Bulgarian'
  },
  {
    value: 'BFA',
    label: 'Burkinabé'
  },
  {
    value: 'BDI',
    label: 'Burundian'
  },
  {
    value: 'CPV',
    label: 'Cabo Verdean'
  },
  {
    value: 'KHM',
    label: 'Cambodian'
  },
  {
    value: 'CMR',
    label: 'Cameroonian'
  },
  {
    value: 'CAN',
    label: 'Canadian'
  },
  {
    value: 'CYM',
    label: 'Caymanian'
  },
  {
    value: 'CAF',
    label: 'Central African'
  },
  {
    value: 'TCD',
    label: 'Chadian'
  },
  {
    value: 'CHL',
    label: 'Chilean'
  },
  {
    value: 'CHN',
    label: 'Chinese'
  },
  {
    value: 'CXR',
    label: 'Christmas Island'
  },
  {
    value: 'CCK',
    label: 'Cocos Island'
  },
  {
    value: 'COL',
    label: 'Colombian'
  },
  {
    value: 'COM',
    label: 'Comoran, Comorian'
  },
  {
    value: 'COG',
    label: 'Congolese'
  },
  {
    value: 'COD',
    label: 'Congolese'
  },
  {
    value: 'COK',
    label: 'Cook Island'
  },
  {
    value: 'CRI',
    label: 'Costa Rican'
  },
  {
    value: 'CIV',
    label: 'Ivorian'
  },
  {
    value: 'HRV',
    label: 'Croatian'
  },
  {
    value: 'CUB',
    label: 'Cuban'
  },
  {
    value: 'CUW',
    label: 'Curaçaoan'
  },
  {
    value: 'CYP',
    label: 'Cypriot'
  },
  {
    value: 'CZE',
    label: 'Czech'
  },
  {
    value: 'DNK',
    label: 'Danish'
  },
  {
    value: 'DJI',
    label: 'Djiboutian'
  },
  {
    value: 'DMA',
    label: 'Dominican'
  },
  {
    value: 'DOM',
    label: 'Dominican'
  },
  {
    value: 'ECU',
    label: 'Ecuadorian'
  },
  {
    value: 'EGY',
    label: 'Egyptian'
  },
  {
    value: 'SLV',
    label: 'Salvadoran'
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinean, Equatoguinean'
  },
  {
    value: 'ERI',
    label: 'Eritrean'
  },
  {
    value: 'EST',
    label: 'Estonian'
  },
  {
    value: 'ETH',
    label: 'Ethiopian'
  },
  {
    value: 'FLK',
    label: 'Falkland Island'
  },
  {
    value: 'FRO',
    label: 'Faroese'
  },
  {
    value: 'FJI',
    label: 'Fijian'
  },
  {
    value: 'FIN',
    label: 'Finnish'
  },
  {
    value: 'FRA',
    label: 'French'
  },
  {
    value: 'GUF',
    label: 'French Guianese'
  },
  {
    value: 'PYF',
    label: 'French Polynesian'
  },
  {
    value: 'ATF',
    label: 'French Southern Territories'
  },
  {
    value: 'GAB',
    label: 'Gabonese'
  },
  {
    value: 'GMB',
    label: 'Gambian'
  },
  {
    value: 'GEO',
    label: 'Georgian'
  },
  {
    value: 'DEU',
    label: 'German'
  },
  {
    value: 'GHA',
    label: 'Ghanaian'
  },
  {
    value: 'GIB',
    label: 'Gibraltar'
  },
  {
    value: 'GRC',
    label: 'Greek, Hellenic'
  },
  {
    value: 'GRL',
    label: 'Greenlandic'
  },
  {
    value: 'GRD',
    label: 'Grenadian'
  },
  {
    value: 'GLP',
    label: 'Guadeloupe'
  },
  {
    value: 'GUM',
    label: 'Guamanian, Guambat'
  },
  {
    value: 'GTM',
    label: 'Guatemalan'
  },
  {
    value: 'GGY',
    label: 'Channel Island'
  },
  {
    value: 'GIN',
    label: 'Guinean'
  },
  {
    value: 'GNB',
    label: 'Bissau-Guinean'
  },
  {
    value: 'GUY',
    label: 'Guyanese'
  },
  {
    value: 'HTI',
    label: 'Haitian'
  },
  {
    value: 'HMD',
    label: 'Heard Island or McDonald Islands'
  },
  {
    value: 'VAT',
    label: 'Vatican'
  },
  {
    value: 'HND',
    label: 'Honduran'
  },
  {
    value: 'HKG',
    label: 'Hong Kong, Hong Kongese'
  },
  {
    value: 'HUN',
    label: 'Hungarian, Magyar'
  },
  {
    value: 'ISL',
    label: 'Icelandic'
  },
  {
    value: 'IND',
    label: 'Indian'
  },
  {
    value: 'IDN',
    label: 'Indonesian'
  },
  {
    value: 'IRN',
    label: 'Iranian, Persian'
  },
  {
    value: 'IRQ',
    label: 'Iraqi'
  },
  {
    value: 'IRL',
    label: 'Irish'
  },
  {
    value: 'IMN',
    label: 'Manx'
  },
  {
    value: 'ISR',
    label: 'Israeli'
  },
  {
    value: 'ITA',
    label: 'Italian'
  },
  {
    value: 'JAM',
    label: 'Jamaican'
  },
  {
    value: 'JPN',
    label: 'Japanese'
  },
  {
    value: 'JEY',
    label: 'Channel Island'
  },
  {
    value: 'JOR',
    label: 'Jordanian'
  },
  {
    value: 'KAZ',
    label: 'Kazakhstani, Kazakh'
  },
  {
    value: 'KEN',
    label: 'Kenyan'
  },
  {
    value: 'KIR',
    label: 'I-Kiribati'
  },
  {
    value: 'PRK',
    label: 'North Korean'
  },
  {
    value: 'KOR',
    label: 'South Korean'
  },
  {
    value: 'KWT',
    label: 'Kuwaiti'
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz'
  },
  {
    value: 'LAO',
    label: 'Lao, Laotian'
  },
  {
    value: 'LVA',
    label: 'Latvian'
  },
  {
    value: 'LBN',
    label: 'Lebanese'
  },
  {
    value: 'LSO',
    label: 'Basotho'
  },
  {
    value: 'LBR',
    label: 'Liberian'
  },
  {
    value: 'LBY',
    label: 'Libyan'
  },
  {
    value: 'LIE',
    label: 'Liechtenstein'
  },
  {
    value: 'LTU',
    label: 'Lithuanian'
  },
  {
    value: 'LUX',
    label: 'Luxembourg, Luxembourgish'
  },
  {
    value: 'MAC',
    label: 'Macanese, Chinese'
  },
  {
    value: 'MKD',
    label: 'Macedonian'
  },
  {
    value: 'MDG',
    label: 'Malagasy'
  },
  {
    value: 'MWI',
    label: 'Malawian'
  },
  {
    value: 'MYS',
    label: 'Malaysian'
  },
  {
    value: 'MDV',
    label: 'Maldivian'
  },
  {
    value: 'MLI',
    label: 'Malian, Malinese'
  },
  {
    value: 'MLT',
    label: 'Maltese'
  },
  {
    value: 'MHL',
    label: 'Marshallese'
  },
  {
    value: 'MTQ',
    label: 'Martiniquais, Martinican'
  },
  {
    value: 'MRT',
    label: 'Mauritanian'
  },
  {
    value: 'MUS',
    label: 'Mauritian'
  },
  {
    value: 'MYT',
    label: 'Mahoran'
  },
  {
    value: 'MEX',
    label: 'Mexican'
  },
  {
    value: 'FSM',
    label: 'Micronesian'
  },
  {
    value: 'MDA',
    label: 'Moldovan'
  },
  {
    value: 'MCO',
    label: 'Monégasque, Monacan'
  },
  {
    value: 'MNG',
    label: 'Mongolian'
  },
  {
    value: 'MNE',
    label: 'Montenegrin'
  },
  {
    value: 'MSR',
    label: 'Montserratian'
  },
  {
    value: 'MAR',
    label: 'Moroccan'
  },
  {
    value: 'MOZ',
    label: 'Mozambican'
  },
  {
    value: 'MMR',
    label: 'Burmese'
  },
  {
    value: 'NAM',
    label: 'Namibian'
  },
  {
    value: 'NRU',
    label: 'Nauruan'
  },
  {
    value: 'NPL',
    label: 'Nepali, Nepalese'
  },
  {
    value: 'NLD',
    label: 'Dutch, Netherlandic'
  },
  {
    value: 'NCL',
    label: 'New Caledonian'
  },
  {
    value: 'NZL',
    label: 'New Zealand, NZ'
  },
  {
    value: 'NIC',
    label: 'Nicaraguan'
  },
  {
    value: 'NER',
    label: 'Nigerien'
  },
  {
    value: 'NGA',
    label: 'Nigerian'
  },
  {
    value: 'NIU',
    label: 'Niuean'
  },
  {
    value: 'NFK',
    label: 'Norfolk Island'
  },
  {
    value: 'MNP',
    label: 'Northern Marianan'
  },
  {
    value: 'NOR',
    label: 'Norwegian'
  },
  {
    value: 'OMN',
    label: 'Omani'
  },
  {
    value: 'PAK',
    label: 'Pakistani'
  },
  {
    value: 'PLW',
    label: 'Palauan'
  },
  {
    value: 'PSE',
    label: 'Palestinian'
  },
  {
    value: 'PAN',
    label: 'Panamanian'
  },
  {
    value: 'PNG',
    label: 'Papua New Guinean, Papuan'
  },
  {
    value: 'PRY',
    label: 'Paraguayan'
  },
  {
    value: 'PER',
    label: 'Peruvian'
  },
  {
    value: 'PHL',
    label: 'Philippine, Filipino'
  },
  {
    value: 'PCN',
    label: 'Pitcairn Island'
  },
  {
    value: 'POL',
    label: 'Polish'
  },
  {
    value: 'PRT',
    label: 'Portuguese'
  },
  {
    value: 'PRI',
    label: 'Puerto Rican'
  },
  {
    value: 'QAT',
    label: 'Qatari'
  },
  {
    value: 'REU',
    label: 'Réunionese, Réunionnais'
  },
  {
    value: 'ROU',
    label: 'Romanian'
  },
  {
    value: 'RUS',
    label: 'Russian'
  },
  {
    value: 'RWA',
    label: 'Rwandan'
  },
  {
    value: 'BLM',
    label: 'Saint Barthélemy, Saint Barth'
  },
  {
    value: 'SHN',
    label: 'Saint Helenian'
  },
  {
    value: 'KNA',
    label: 'Kittitian or Nevisian'
  },
  {
    value: 'LCA',
    label: 'Saint Lucian'
  },
  {
    value: 'MAF',
    label: 'Saint-Martinoise'
  },
  {
    value: 'SPM',
    label: 'Saint-Pierrais or Miquelonnais'
  },
  {
    value: 'VCT',
    label: 'Saint Vincentian, Vincentian'
  },
  {
    value: 'WSM',
    label: 'Samoan'
  },
  {
    value: 'SMR',
    label: 'Sammarinese'
  },
  {
    value: 'STP',
    label: 'São Toméan'
  },
  {
    value: 'SAU',
    label: 'Saudi, Saudi Arabian'
  },
  {
    value: 'SEN',
    label: 'Senegalese'
  },
  {
    value: 'SRB',
    label: 'Serbian'
  },
  {
    value: 'SYC',
    label: 'Seychellois'
  },
  {
    value: 'SLE',
    label: 'Sierra Leonean'
  },
  {
    value: 'SGP',
    label: 'Singaporean'
  },
  {
    value: 'SXM',
    label: 'Sint Maarten'
  },
  {
    value: 'SVK',
    label: 'Slovak'
  },
  {
    value: 'SVN',
    label: 'Slovenian, Slovene'
  },
  {
    value: 'SLB',
    label: 'Solomon Island'
  },
  {
    value: 'SOM',
    label: 'Somali, Somalian'
  },
  {
    value: 'ZAF',
    label: 'South African'
  },
  {
    value: 'SGS',
    label: 'South Georgia or South Sandwich Islands'
  },
  {
    value: 'SSD',
    label: 'South Sudanese'
  },
  {
    value: 'ESP',
    label: 'Spanish, Castilian'
  },
  {
    value: 'LKA',
    label: 'Sri Lankan'
  },
  {
    value: 'SDN',
    label: 'Sudanese'
  },
  {
    value: 'SUR',
    label: 'Surinamese'
  },
  {
    value: 'SJM',
    label: 'Svalbard'
  },
  {
    value: 'SWZ',
    label: 'Swazi'
  },
  {
    value: 'SWE',
    label: 'Swedish'
  },
  {
    value: 'CHE',
    label: 'Swiss'
  },
  {
    value: 'SYR',
    label: 'Syrian'
  },
  {
    value: 'TWN',
    label: 'Taiwanese'
  },
  {
    value: 'TJK',
    label: 'Tajikistani'
  },
  {
    value: 'TZA',
    label: 'Tanzanian'
  },
  {
    value: 'THA',
    label: 'Thai'
  },
  {
    value: 'TLS',
    label: 'Timorese'
  },
  {
    value: 'TGO',
    label: 'Togolese'
  },
  {
    value: 'TKL',
    label: 'Tokelauan'
  },
  {
    value: 'TON',
    label: 'Tongan'
  },
  {
    value: 'TTO',
    label: 'Trinidadian or Tobagonian'
  },
  {
    value: 'TUN',
    label: 'Tunisian'
  },
  {
    value: 'TUR',
    label: 'Turkish'
  },
  {
    value: 'TKM',
    label: 'Turkmen'
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos Island'
  },
  {
    value: 'TUV',
    label: 'Tuvaluan'
  },
  {
    value: 'UGA',
    label: 'Ugandan'
  },
  {
    value: 'UKR',
    label: 'Ukrainian'
  },
  {
    value: 'ARE',
    label: 'Emirati, Emirian, Emiri'
  },
  {
    value: 'GBR',
    label: 'British, UK'
  },
  {
    value: 'USA',
    label: 'American'
  },
  {
    value: 'URY',
    label: 'Uruguayan'
  },
  {
    value: 'UZB',
    label: 'Uzbekistani, Uzbek'
  },
  {
    value: 'VUT',
    label: 'Ni-Vanuatu, Vanuatuan'
  },
  {
    value: 'VEN',
    label: 'Venezuelan'
  },
  {
    value: 'VNM',
    label: 'Vietnamese'
  },
  {
    value: 'VGB',
    label: 'British Virgin Island'
  },
  {
    value: 'VIR',
    label: 'U.S. Virgin Island'
  },
  {
    value: 'WLF',
    label: 'Wallis and Futuna, Wallisian or Futunan'
  },
  {
    value: 'ESH',
    label: 'Sahrawi, Sahrawian, Sahraouian'
  },
  {
    value: 'YEM',
    label: 'Yemeni'
  },
  {
    value: 'ZMB',
    label: 'Zambian'
  },
  {
    value: 'ZWE',
    label: 'Zimbabwean'
  }
];
export default labelList;
