import React, { useState, useCallback } from 'react';

const CarouselImage = ({ images }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleDotClick = useCallback((index) => {
    setCurrentImageIndex(index);
  }, []);

  return (
    <div className="carousel">
      <div className="carousel__images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`image ${index}`}
            className={`carousel__image ${
              index === currentImageIndex ? 'carousel__image--active' : ''
            }`}
            style={{ maxWidth: '88%', marginTop: 24, marginBottom: 24 }}
          />
        ))}
      </div>

      <div className="carousel__dots">
        {images.map((_, index) => (
          <React.Fragment key={index}>
            <button
              className={`carousel__dot ${
                index === currentImageIndex ? 'carousel__dot--active' : ''
              }`}
              onClick={() => handleDotClick(index)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CarouselImage;
