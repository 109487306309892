import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Table } from 'antd';
import { parseQueryString } from '../../../helpers/parseQueryString';
import styles from './StateroomTable.module.css';

import useStateroomTable from './useStateroomTable';
import useStore from '../../../store/userStore';
import { useLocation } from 'react-router-dom';

const StateroomTable = ({ cabins, cabinData, setCabineData }) => {
  const { setPricingData, setCabinDataStore, setGuests } = useStore();
  const [selectedCabin, setSelectedCabin] = useState({});
  const location = useLocation();

  // set default cabin
  useLayoutEffect(() => {
    if (cabins && cabins.length) {
      const defaultCheapestData = {
        ...cabins[0],
        code: cabins[0]?.code,
        price: cabins[0].prices?.standard / 2,
        passenger: 2
      };

      setSelectedCabin(defaultCheapestData);

      // update global state
      setCabineData((prev) => ({
        ...prev,
        ...defaultCheapestData
      }));
    }
  }, [cabins]);

  // change cabin in table
  useEffect(() => {
    // update store
    const v = selectedCabin.price * selectedCabin.passenger;
    setPricingData(v); // price selected cabin

    // update global state
    setCabineData((prev) => ({ ...prev, ...selectedCabin }));

    let cabin = cabins.find((cabin) => cabin.code === selectedCabin.code);
    cabin = { ...cabin, ...cabin?.prices };
    setGuests(selectedCabin.passenger);

    // update cabinData for 4 step
    setCabinDataStore({
      cabine: cabin,
      decks: cabinData.decks,
      ship: cabinData.ship,
      cabin: cabins,
      rate_code: cabinData.rate_code,
      passenger: selectedCabin.passenger,
      grade: selectedCabin.code,
      sailing_code: parseQueryString(location).code,
      code: parseQueryString(location).code,
      staterooms: 1,
      source: parseQueryString(location).source
    });
  }, [selectedCabin]);

  const { columns, dataTable, keys } = useStateroomTable(cabins, selectedCabin, handleSelectCabin);

  function handleSelectCabin(e) {
    const value = JSON.parse(e.target.value);
    setSelectedCabin(value);
  }

  return (
    <Table
      bordered
      size={'small'}
      className={styles.stateroomTable}
      columns={columns}
      dataSource={dataTable}
      scroll={{
        x: 1000
      }}
      sticky
      pagination={false}
      onHeaderRow={() => ({
        className: styles.stateroomTableHeader
      })}
      expandable={{
        expandedRowKeys: keys,
        defaultExpandAllRows: true,
        showExpandColumn: false,
        expandedRowClassName: () => styles.expendedRow,
        expandedRowRender: (record) => (
          <p className={styles.expendedRowText}>{record.description}</p>
        )
      }}
    />
  );
};

export default React.memo(StateroomTable);
