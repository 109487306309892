/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ButtonPrimary from '../../components/UI/ButtonPrimary';
import useStore from '../../store/userStore';

export const GuestChoosing = ({ cabins }) => {
  const {
    setGuests: setGuestsStore,
    setStageStateroomsSelect,
    setAdults,
    setChildren,
    adults,
    children,
    infants
  } = useStore();

  const [available, setAvailable] = useState({ adults: [], children: false });
  const [selected, setSelected] = useState({ adults: 0, children: 0 });
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const availability = { adults: [], children: false };

      cabins.forEach((cabin) => {
        const { prices } = cabin;

        prices.forEach((price) => {
          const { standard, single, third, fourth, child } = price;

          if (parseFloat(standard) > 0 && !availability.adults.includes(2)) {
            availability.adults.push(2);
          }
          if (parseFloat(single) > 0 && !availability.adults.includes(1)) {
            availability.adults.push(1);
          }
          if (parseFloat(third) > 0 && !availability.adults.includes(3)) {
            availability.adults.push(3);
          }
          if (parseFloat(fourth) > 0 && !availability.adults.includes(4)) {
            availability.adults.push(4);
          }
          if (parseFloat(child) > 0) {
            availability.children = true;
          }
        });
      });

      setAvailable(availability);
      setSelected({
        adults: availability.adults.includes(2) ? 2 : Math.max(...availability.adults),
        children: 0
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }
  }, [cabins]);

  useEffect(() => {
    setGuestsStore(selected.adults + selected.children);
    setAdults(selected.adults);
    if (selected.children >= 1) {
      setChildren(selected.children);
    }
  }, [selected]);

  const handleDecreaseAdult = () => {
    if (!error && selected.adults > Math.min(...available.adults)) {
      setSelected((prevState) => ({ ...prevState, adults: prevState.adults - 1 }));
    }
  };

  const handleIncreaseAdult = () => {
    if (!error && selected.adults < Math.max(...available.adults)) {
      setSelected((prevState) => ({ ...prevState, adults: prevState.adults + 1 }));
    }
  };

  const handleDecreaseChild = () => {
    if (!error && selected.children > 0) {
      setSelected((prevState) => ({ ...prevState, children: prevState.children - 1 }));
    }
  };

  const handleIncreaseChild = () => {
    if (!error && available.children && selected.children < 1) {
      setSelected((prevState) => ({ ...prevState, children: prevState.children + 1 }));
    }
  };

  const nextStepHandler = () => {
    if (!error) {
      setGuestsStore(selected.adults);
      setStageStateroomsSelect(3);
    }
  };

  return (
    <div className="p-12 guests-num">
      <h2>
        Assign guests to: <b>Stateroom</b>?
      </h2>
      <div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <RemoveCircleOutlineIcon
                sx={{
                  fontSize: 40,
                  color:
                    !error && selected.adults > Math.min(...available.adults) ? 'black' : 'gray'
                }}
                onClick={handleDecreaseAdult}
              />
              <span style={{ fontSize: 40 }}>{selected.adults}</span>
              <AddCircleOutlineIcon
                sx={{
                  fontSize: 40,
                  color:
                    !error && selected.adults < Math.max(...available.adults) ? 'black' : 'gray'
                }}
                onClick={handleIncreaseAdult}
              />
            </div>
            <b style={{ textTransform: 'uppercase' }}>adults</b>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <div>
              <RemoveCircleOutlineIcon
                sx={{
                  fontSize: 40,
                  color: !error && selected.children > 0 ? 'black' : 'gray'
                }}
                onClick={handleDecreaseChild}
              />
              <span style={{ fontSize: 40 }}>{selected.children >= 0 ? selected.children : 0}</span>
              <AddCircleOutlineIcon
                sx={{
                  fontSize: 40,
                  color: (!error && selected.children == 1) || !selected.children ? 'gray' : 'black'
                }}
                onClick={handleIncreaseChild}
              />
            </div>
            <b style={{ textTransform: 'uppercase' }}>children</b>
          </div>
        </div>
      </div>
    </div>
  );
};
