import React from 'react';
import { Divider } from 'antd';
import PortsInfo from '../PortsInfo';
import StateroomInfo from '../StateroomInfo';
import DateInfo from '../DateInfo';
import PriceInfo from '../PriceInfo';

const SummaryInfo = ({
  dateInfo = false,
  portsInfo = false,
  stateroomInfo = false,
  priceInfo = false
}) => {
  return (
    <>
      {dateInfo && (
        <>
          <Divider />
          <DateInfo />
        </>
      )}
      {priceInfo && (
        <>
          <Divider />
          <PriceInfo />
        </>
      )}

      {portsInfo && (
        <>
          <Divider />
          <PortsInfo />
        </>
      )}

      {stateroomInfo && (
        <>
          <Divider />
          <StateroomInfo />
        </>
      )}
    </>
  );
};

export default SummaryInfo;
