import React from 'react';
import { Typography, Card, Row, Col } from 'antd';
import useStore from '../../../store/userStore';
import countryList from '../../../countries.data';

const { Title, Text } = Typography;

const BookingPassengers = () => {
  const { passengersData, billingData } = useStore();

  return (
    <Card className={'shadow'}>
      <Title level={2} className={'fs-2 fw-bold'}>
        Passengers
      </Title>
      <Row gutter={[30, 40]}>
        {passengersData.length > 0 &&
          passengersData.map((passenger, index) => {
            const day = passenger.dob.$D;
            const month = passenger.dob.$M.length > 1 ? `0${passenger.dob.$M}` : passenger.dob.$M;
            const year = passenger.dob.$y;

            const date = `${day}/${month}/${year}`;

            return (
              <Col
                key={`passenger-${index}`}
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className={'d-flex flex-wrap justify-content-start align-items-start fw-bold'}
                // style={{ gap: '15px 25%' }}
              >
                <Text className={'fs-5'}>{passenger.title}</Text>
                <Text className={'fs-5'}>{passenger.firstName}</Text>
                <Text className={'fs-5'}>{passenger.surname}</Text>
                <Text className={'fs-5'} style={{ flexBasis: '100%' }}>
                  {date}
                </Text>
              </Col>
            );
          })}

        {billingData && (
          <Col
            flex="1 1 100%"
            className={'d-flex flex-wrap justify-content-start align-items-start fw-bold'}
            // style={{ gap: '15px 5%' }}
          >
            <Text className={'fs-5'}>{billingData.billingAddress}</Text>
            <Text className={'fs-5'}>{billingData.city}</Text>
            <Text className={'fs-5'}>{billingData.state}</Text>
            <Text className={'fs-5'}>{billingData.zipCode}</Text>
            <Text className={'fs-5'}>
              {countryList.find((item) => item.value === billingData.country).label}
            </Text>
            <div className={'d-flex justify-content-start'}>
              <Text className={'fs-5'}>{billingData.email}</Text>
              <Text className={'fs-5'}>{billingData.phone}</Text>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default BookingPassengers;
