export function getCSVStringFromArray(arr) {
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  let result = '';
  arr.forEach((val, index) => {
    if (index < arr.length - 1) {
      result += `${regionNamesInEnglish.of(val.toUpperCase())}, `;
    } else {
      result += `${regionNamesInEnglish.of(val.toUpperCase())}.`;
    }
  });
  return result;
}
