import React from 'react';

const UserIcon = (props) => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M29.5 0C33.4119 0 37.1637 1.55401 39.9298 4.32017C42.696 7.08634 44.25 10.8381 44.25 14.75C44.25 18.6619 42.696 22.4137 39.9298 25.1798C37.1637 27.946 33.4119 29.5 29.5 29.5C25.5881 29.5 21.8363 27.946 19.0702 25.1798C16.304 22.4137 14.75 18.6619 14.75 14.75C14.75 10.8381 16.304 7.08634 19.0702 4.32017C21.8363 1.55401 25.5881 0 29.5 0ZM29.5 36.875C45.7988 36.875 59 43.4756 59 51.625V59H0V51.625C0 43.4756 13.2012 36.875 29.5 36.875Z"
      fill="#676767"
    />
  </svg>
);

export default UserIcon;
