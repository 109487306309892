const getGuestCountToObj = (guests) => {
  if (guests) {
    if (guests === 1) {
      return 'single';
    }
    if (guests === 2) {
      return 'standard';
    }
    if (guests === 3) {
      return 'third';
    }
    if (guests === 4) {
      return 'fourth';
    }
  }
};

export default getGuestCountToObj;
