import React from 'react';
import { Skeleton } from 'antd';
import '../../components/searchComponent/new-design.css';
import useStore from '../../store/userStore';
export const ShipBaner = ({ shipName, shipImage }) => {
  const { options: fetchOptions } = useStore();

  //bg ship
  const dummyImgUrl =
    'https://teemingweb.cabinselect.com/wp-content/uploads/sites/4/2023/05/dummy-post-horisontal.jpeg';
  const baner = shipImage ?? dummyImgUrl;
  const backgroundImageUrl = 'url(' + baner + ')';
  return (
    <div
      className="h-300 ship-baner new-design"
      style={{ backgroundImage: backgroundImageUrl, backgroundSize: 'cover' }}>
      {shipName ? (
        <h2
          style={{ backgroundColor: fetchOptions.search_app_color, color: 'white' }}
          className="banerFont">
          {shipName}
        </h2>
      ) : (
        <Skeleton.Input active={true} />
      )}
    </div>
  );
};
