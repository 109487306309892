import React from 'react';
import SearchComponent from '../components/searchComponent/SearchComponent';

const HomePage = () => {
  return (
    <div className="container justify-content-md-center new-design">
      <div>
        <SearchComponent />
      </div>
    </div>
  );
};

export { HomePage };
