import React from 'react';
import PropTypes from 'prop-types';

function TextBlock({ title, text }) {
  return (
    <div
      // style={{
      //   marginTop: 24,
      //   marginBottom: 12,
      //   padding: '16px',
      //   border: '1px solid #ddd',
      //   borderRadius: '5px',
      //   boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      //   transition: 'box-shadow 0.3s ease-in-out'
      // }}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0px 0px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.1)';
      }}>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
}

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default TextBlock;
