import React from 'react';
import { Card, Typography, List } from 'antd';
import userStore from '../../../store/userStore';

import removeTrailingBrTags from '../../../helpers/removeTrailingBrTags';
import { formatDate } from '../../../helpers/formatDate';
import addDaysToDate from '../../../helpers/addDaysToDate';

const { Title, Text } = Typography;
const { Item } = List;

const BookingItinerary = () => {
  const { itinerary, embark } = userStore();

  return (
    <Card className={'shadow'}>
      <Title level={2} className={'fs-2 fw-bold'}>
        Itinerary
      </Title>

      <List itemLayout={'vertical'}>
        {itinerary?.length > 0 &&
          itinerary.map((item, index) => (
            <Item key={index} className={'pe-0 ps-0'}>
              <header className={'d-flex flex-wrap align-items-baseline gap-2'}>
                <Text className={'fs-2'}>Day {item.day}</Text>
                <Text className={'fs-3'}>
                  {embark && formatDate(addDaysToDate(embark, index + 1))}
                </Text>
              </header>
              <div
                dangerouslySetInnerHTML={{
                  __html: removeTrailingBrTags(item.description)
                }}
              />
            </Item>
          ))}
      </List>
    </Card>
  );
};

export default BookingItinerary;
