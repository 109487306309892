/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import './styles.css';
import { formatDate } from '../../helpers/formatDate';
import useStore from '../../store/userStore';
function DetailsContainer(props) {
  const { options: fetchOptions } = useStore();
  const click = useCallback(() => {
    props.navigate();
  }, [props]);
  return (
    <div
      style={{
        minWidth: '800px',
        maxWidth: '900px',
        margin: '0 auto'
      }}>
      <div className="row details-container text-center">
        <div
          className="col"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p style={{ fontSize: '20px', marginBottom: '5px' }}>Dates</p>
          <p style={{ fontWeight: 'bold', fontSize: 12, whiteSpace: 'nowrap' }}>
            {props.dates && formatDate(props.dates)}
          </p>
        </div>
        <div
          className="col"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p style={{ fontSize: '20px', marginBottom: '5px' }}>Ship</p>
          <p style={{ fontWeight: 'bold', fontSize: 12, whiteSpace: 'nowrap' }}>
            {props.ship && props.ship}
          </p>
        </div>
        <div
          className="col"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p style={{ fontSize: '20px', marginBottom: '5px' }}>Duration</p>
          <p style={{ fontWeight: 'bold', fontSize: 12 }}>
            {props.duration && props.duration} Nights
          </p>
        </div>
        {props.guest && (
          <div
            className="col"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <p style={{ fontSize: '20px', marginBottom: '5px' }}>Guest</p>
            <p style={{ fontWeight: 'bold', fontSize: 12 }}>{props.guest && props.guest}</p>
          </div>
        )}
        {props.staterooms && (
          <div
            className="col"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <p style={{ fontSize: '20px', marginBottom: '5px' }}>Staterooms</p>
            <p style={{ fontWeight: 'bold', fontSize: 12 }}>
              {props.staterooms && props.staterooms}
            </p>
          </div>
        )}
        <div className="col">
          <button
            onClick={click}
            style={{
              backgroundColor: fetchOptions.search_app_color,
              border: 'none',
              color: '#fff',
              padding: '6px 12px',
              borderRadius: '6px',
              cursor: 'pointer',
              fontSize: '18px',
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 180,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <span>From $ {props.price && props.price}</span>
            <span>BOOK NOW</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DetailsContainer;
