import { apiSearch } from './apiSearch';

export const reservationAPI = apiSearch.injectEndpoints({
  endpoints: (builder) => ({
    createReservation: builder.mutation({
      query: ({ sailing_code, source, session, language, rate_code, promotions, cabins }) => ({
        url: `/reservation/${sailing_code}/${source}/`,
        body: {
          session,
          language,
          rate_code,
          promotions,
          cabins
        },
        method: 'POST'
      }),
      invalidatesTags: ['Reservation']
    })
  }),
  overrideExisting: true
});

export const { useCreateReservationMutation, useCreateOpaquePaymentMutation } = reservationAPI;
