/* eslint-disable react/prop-types */
import React from 'react';
import '../../styles/baner.css';
import { PropTypes } from 'prop-types';
export default function Baner(props) {
  const img = props.img
    ? props.img
    : 'https://teemingweb.cabinselect.com/wp-content/uploads/sites/4/2023/05/dummy-post-horisontal.jpeg';

  const backgroundImageUrl = 'url(' + img + ')';

  return (
    <div
      className="baner-container"
      style={{
        backgroundImage: backgroundImageUrl,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div>
        <h1
          className="baner-header-bold"
          style={{ backgroundColor: 'aliceblue', borderRadius: 8, padding: 4 }}>
          {props.title ? props.title : 'Lorem Ipsum Baner'}
        </h1>
      </div>
    </div>
  );
}

Baner.propTypes = { title: PropTypes.string.isRequired };
