import { apiSearch } from './apiSearch';
import formatParamsArray from '../helpers/formatParamsArray';
import { getOptions } from '../helpers/getOptions';
export const pricingAPI = apiSearch.injectEndpoints({
  endpoints: (builder) => ({
    getPricing: builder.query({
      query: ({
        sailing_code,
        session,
        source,
        language = getOptions('react_app_cabin_select_api_language'),
        rate_code,
        grade_code,
        cabin_number,
        guest_dob,
        promotions,
        guest_count,
        extra_code = [],
        extra_usage = []
      }) => {
        if (!session) throw new Error('Session is required');
        if (guest_count === 0) throw new Error('Guest count must be greater than 0');
        const queryParams = {
          sailing_code,
          session,
          source,
          language,
          rate_code,
          grade_code,
          cabin_number,
          promotions,
          guest_count: guest_count,
          ...formatParamsArray(extra_code, 'extra_code'),
          ...formatParamsArray(extra_usage, 'extra_usage')
        };
        const dobParams = {};
        if (guest_dob.length <= 0) {
          for (let i = 1; i <= guest_count; i++) {
            dobParams[`guest_dob_${i}`] = '1999-02-02';
          }
        } else {
          guest_dob.forEach((dob, index) => {
            dobParams[`guest_dob_${index + 1}`] = dob[`guest_dob_${index + 1}`];
          });
        }
        return {
          url: `/pricing/${sailing_code}/`,
          params: {
            ...queryParams,
            ...dobParams
          },
          method: 'GET'
        };
      },
      providesTags: ['Pricing']
    })
  }),
  overrideExisting: true
});

export const { useGetPricingQuery } = pricingAPI;
