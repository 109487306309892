export const calcPrice = (selectedCabin, passengerCount, adultCount, childCount, infantCount) => {
  const cabinMappingType = {
    1: 'single',
    2: 'standard',
    3: 'third',
    4: 'fourth'
  };
  // console.log(selectedCabin);

  if (passengerCount === 1) {
    return selectedCabin['single'];
  } else if (passengerCount === 2) {
    return parseFloat(selectedCabin['standard']) * 2;
  } else if (passengerCount > 2) {
    let tempPrice = parseFloat(selectedCabin['standard']) * 2;
    // console.log('selectedCabin', selectedCabin);

    let tempCount = 3;
    while (tempCount <= passengerCount) {
      let nextPriceKey;
      if (tempCount <= adultCount) {
        nextPriceKey = selectedCabin[cabinMappingType[tempCount]]
          ? cabinMappingType[tempCount]
          : 'extra';
      } else if (tempCount <= adultCount + childCount) {
        nextPriceKey = selectedCabin['child'] ? 'child' : 'extra';
      } else if (tempCount <= adultCount + childCount + infantCount) {
        nextPriceKey = selectedCabin['infant'] ? 'infant' : 'extra';
      }

      tempPrice += parseFloat(selectedCabin[nextPriceKey] || 0); // Add a fallback value of 0 if the property is undefined
      tempCount += 1;
    }

    return tempPrice;
  }
};
