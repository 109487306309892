import React from 'react';
import { useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ paths }) => {
  const navigation = useNavigate();
  const onClickHandler = (index, arr) => {
    const num = -(arr.length - (index + 1));
    if (num < 0) navigation(num);
  };
  return (
    <nav>
      <ul className="breadcrumb">
        {paths.map((path, index, arr) => (
          <React.Fragment key={index}>
            <li
              className={
                index === paths.length - 1 ? 'breadcrumb-component-last' : 'breadcrumb-component'
              }
              onClick={() => onClickHandler(index, arr)}>
              {path}
            </li>
            {index < paths.length - 1 && <li className="breadcrumb-separator">{'>'}</li>}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
