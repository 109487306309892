import React from 'react';

const UserInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.9998 0C16.9889 0 18.8966 0.790176 20.3031 2.1967C21.7096 3.60322 22.4997 5.51088 22.4997 7.5C22.4997 9.48912 21.7096 11.3968 20.3031 12.8033C18.8966 14.2098 16.9889 15 14.9998 15C13.0107 15 11.1031 14.2098 9.69658 12.8033C8.29008 11.3968 7.49991 9.48912 7.49991 7.5C7.49991 5.51088 8.29008 3.60322 9.69658 2.1967C11.1031 0.790176 13.0107 0 14.9998 0ZM14.9998 18.75C23.2872 18.75 29.9996 22.1063 29.9996 26.25V30H0V26.25C0 22.1063 6.71242 18.75 14.9998 18.75Z"
        fill="white"
      />
    </svg>
  </div>
);

export default UserInputIcon;
