import { useState, useEffect } from 'react';
import { useLoginMutation } from '../services/AuthService';
import { useGetSessionMutation } from '../services/SessionService';
import Cookies from 'js-cookie';
import { getOptions } from '../helpers/getOptions';

const useAuth = () => {
  const halfHour = 0.5;
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + halfHour * 60 * 60 * 1000);

  const [getJwtToken] = useLoginMutation();
  const [getSessionKey] = useGetSessionMutation();
  const [token, setToken] = useState(Cookies.get('token') || null);
  const [sessionKey, setSessionKey] = useState(Cookies.get('sessionKey') || null);
  const [isLoading, setIsLoading] = useState(!token);
  const [error, setError] = useState(null);

  const login = async () => {
    try {
      const username = getOptions('react_app_cabin_select_api_username');
      const password = getOptions('react_app_cabin_select_api_password');
      const response = await getJwtToken({ username, password });
      const newToken = response?.data?.token;
      if (newToken) {
        setToken(newToken);
        Cookies.set('token', newToken, { expires: expirationDate });
      } else {
        throw new Error('Failed to get JWT token');
      }
    } catch (error) {
      console.error('Failed to get JWT token:', error);
      setError('Failed to get JWT token');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshSessionKey = async () => {
    try {
      const agency = { agency: getOptions('react_app_cabin_select_api_agency') };
      const agent = getOptions('react_app_cabin_select_api_agent');
      const response = await getSessionKey({ token, agency, agent });
      const newSessionKey = response?.data?.key;
      if (newSessionKey) {
        setSessionKey(newSessionKey);
        Cookies.set('sessionKey', newSessionKey, { expires: expirationDate });
      } else {
        throw new Error('Failed to get session key');
      }
    } catch (error) {
      console.error('Failed to get session key:', error);
      setError('Failed to get session key');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      login();
    }
  }, []);

  useEffect(() => {
    if (token && !sessionKey) {
      refreshSessionKey();
    }
  }, [token]);

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('sessionKey');
    setToken(null);
    setSessionKey(null);
    setError(null);
  };

  return { token, sessionKey, isLoading, error, login, logout };
};

export default useAuth;
