import { Affix, Col, Row } from 'antd';
import React from 'react';
import styles from './TwoColumnLayout.module.css';
import { useMediaQuery } from 'react-responsive';

const TwoColumnLayout = ({ fixed = false, firstColumnContent, secondColumnContent }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  return (
    <div className="container-xxl">
      <Row gutter={[30, 30]}>
        <Col span={24} md={15} lg={16} xl={17}>
          {firstColumnContent}
        </Col>
        <Col span={24} md={9} lg={8} xl={7}>
          {!fixed || isMobile ? (
            <div className={styles.sidebar}>{secondColumnContent}</div>
          ) : (
            <Affix offsetTop={10}>
              <div className={styles.sidebar}>{secondColumnContent}</div>
            </Affix>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TwoColumnLayout;
