/* eslint-disable no-unused-vars */
import { Space } from 'antd';
import React, { useState } from 'react';
import './CruiseItem.css';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helpers/formatDate';
import useStore from '../../store/userStore';
import { getRelativePath } from '../../helpers/getRelativePath';
import { getCSVStringFromArray } from '../../helpers/getCSVStringFromArray';
import { getPortValues } from '../../helpers/getPortValues';
import '../searchComponent/new-design.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShip,
  faMapMarkerAlt,
  faCalendar,
  faCompass,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import { showCurrency } from '../../helpers/showCurrency';
import { useEffect } from 'react';
export const CruiseItem = (props) => {
  const navigate = useNavigate();
  const {
    image,
    shipName,
    cruiseName,
    region,
    departure,
    itinerary,
    night,
    code,
    price,
    source,
    description,
    currency
  } = props;

  const { selectShipCode, options: fetchOptions, setCurrency } = useStore();
  const [showMore, setShowMore] = useState(false);
  const bookHandler = () => {
    selectShipCode(code);

    navigate(`/stateroom?code=${code}&source=${source}`, {
      state: {
        code: code
      }
    });
  };

  useEffect(() => {
    setCurrency(currency);
  }, [currency]);

  const iconBlockStyle = {
    margin: 0
    // width: '20%'
  };

  const blockStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    width: 320
    // justifyContent: 'space-between'
  };

  return (
    <div className="item-wrapper new-design result">
      <img
        src={
          image
            ? image
            : 'https://teemingweb.cabinselect.com/wp-content/uploads/sites/4/2023/05/dummy-post-horisontal.jpeg'
        }
        alt="My Image"
        style={{ objectFit: 'cover', height: 300, width: '100%', padding: 0 }}
        className="cruise-meta"
      />
      <div className="cruise-meta">
        <div className="cruise-meta-column">
          <h3>{cruiseName}</h3>
        </div>
      </div>
      <div
        style={{
          paddingRight: 12,
          paddingLeft: 12,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'self-start'
        }}>
        {showMore ? (
          <div style={{ height: 120 }}>
            <p>
              <span className="meta">Descriptions: </span>
              {description ? description : 'NO DESCRIPTIONS'}
            </p>
          </div>
        ) : (
          <>
            <div style={blockStyle}>
              <p style={iconBlockStyle}>
                <FontAwesomeIcon icon={faShip} className="icon" />
              </p>
              <div style={style.textBlock}>
                <span className="meta">Ship name: </span>
                {shipName && shipName}
              </div>
            </div>

            <div style={blockStyle}>
              <div style={iconBlockStyle}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              </div>
              <div style={style.textBlock}>
                <span className="meta">Region:</span> {region && getCSVStringFromArray(region)}
              </div>
            </div>

            <div style={blockStyle}>
              <div style={iconBlockStyle}>
                <FontAwesomeIcon icon={faCalendar} className="icon" />
              </div>
              <div style={style.textBlock}>
                <span className="meta">Departure date:</span> {formatDate(departure)}
              </div>
            </div>

            <div style={blockStyle}>
              <div style={iconBlockStyle}>
                <FontAwesomeIcon icon={faCompass} className="icon" />
              </div>
              <div style={style.textBlock}>
                <span className="meta">Itinerary:</span> {getPortValues(itinerary)}
              </div>
            </div>

            <div style={style.lastBlock}>
              <div style={iconBlockStyle}>
                <FontAwesomeIcon icon={faClock} className="icon" />
              </div>
              <div style={style.textBlock}>
                <span className="meta">Days/Night: </span>
                {night}
              </div>
            </div>
          </>
        )}

        <Space />
        <p className="btn-book-cruise">
          from <b style={{ fontSize: 24 }}>{showCurrency(currency, price)}</b>
        </p>
      </div>
      <div
        className="result-ctas"
        style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <button onClick={() => setShowMore((prev) => !prev)}>
          {showMore ? 'Read less' : 'Read more'}
        </button>
        <button
          onClick={() => bookHandler()}
          style={{ backgroundColor: fetchOptions.search_app_color }}>
          Next
        </button>
      </div>
    </div>
  );
};

const style = {
  iconBlock: { display: 'flex', alignItems: 'center', marginTop: 12, marginBottom: 6 },
  textBlock: {
    width: 280
  },
  lastBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '22px',
    width: 320
  }
};
