import { createSlice } from '@reduxjs/toolkit';
import { getOptions } from '../../helpers/getOptions';

const initialState = {
  username: getOptions('react_app_cabin_select_api_username'),
  password: getOptions('react_app_cabin_select_api_password'),
  uuid: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    saveSession(state, action) {
      state.uuid = action.payload;
    }
  }
});

export default authSlice.reducer;
