/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './App.css';
import Layout from './components/Layout';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { SearchResult } from './pages/SearchResultPage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { PricingPage } from './pages/PricingPage';
import PricingContentPage from './pages/pricing&content/PricingContentPage';
import { Deckplans } from './pages/Deckplans';
import { BasketPage } from './pages/upsell/BasketPage';
import { fetchOptions } from './helpers/fetchOptions';
import useStore from './store/userStore';
import { getRelativePath } from './helpers/getRelativePath';
import Stateroom from './pages/stateroom/Stateroom';
import BookingConfirmationPage from './pages/BookingConfirmationPage';
import { getOptions } from './helpers/getOptions';
function App() {
  const { options, setOptions } = useStore();
  useEffect(() => {
    if (options.react_app_cabin_select_api_username === undefined) {
      fetchOptions(setOptions);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const agencyParam = urlParams.get('agency');
    const agentParam = urlParams.get('agent');
    const agencyInCookie = getOptions('react_app_cabin_select_api_agency');
    const agentInCookie = getOptions('react_app_cabin_select_api_agent');
    if (agencyParam && agencyParam !== agencyInCookie) {
      console.log('set query agency -', agencyParam);
      sessionStorage.setItem('react_app_cabin_select_api_agency', agencyParam);
    }
    if (agentParam && agentParam !== agentInCookie) {
      sessionStorage.setItem('react_app_cabin_select_api_agent', agentParam);
    }
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/search-result'} element={<SearchResult />} />
          <Route path={'/stateroom'} element={<Stateroom />} />
          <Route path={'/cruise'} element={<PricingPage />} />
          <Route path={'/pricing&content'} element={<PricingContentPage />} />
          <Route path={'/cabin'} element={<Deckplans />} />
          <Route path={'/upsell'} element={<BasketPage />} />
          <Route path={'/booking-confirmation'} element={<BookingConfirmationPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
