export function getPortValues(arr) {
  let firstPortValue;
  let lastPortValue;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].port) {
      if (firstPortValue == undefined) {
        firstPortValue = arr[i].port;
      }
    }
    lastPortValue = arr[i].port;
  }
  return firstPortValue + ' - ' + lastPortValue;
}
