/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import DetailsContainer from '../components/PricingComponents/DetailsContainer';
import GrayBlock from '../components/PricingComponents/GrayBlock';
import TextBlock from '../components/PricingComponents/TextBlock';
import Baner from '../components/UI/Baner';
import { useCruiseDetailQuery } from '../services/SearchService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useAuth from '../hooks/useSession';
import PricingContentPage from './pricing&content/PricingContentPage';
import useStore from '../store/userStore';

const PricingPage = () => {
  const { selectedShipCode, addCruiseData } = useStore();
  function parseQueryString() {
    let search = location.search.substring(1);
    return JSON.parse(
      '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );
  }
  const location = useLocation();
  const [cabineData, setCabineData] = useState({});
  const navigate = useNavigate();
  const { sessionKey } = useAuth();
  const { data } = useCruiseDetailQuery({
    session: sessionKey,
    sailing_code: parseQueryString(location.search).code,
    source: parseQueryString(location.search).source
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    data && addCruiseData(data);
  }, [data]);

  const navHandler = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const codeToName = (val) => {
    const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNamesInEnglish.of(val);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

  return data ? (
    <>
      <Baner title={data ? data.cruise.name : 'Lorem Ipsum'} img={data && data.ship.images[0]} />

      <div className="container d-flex justify-content-center flex-column new-design">
        <DetailsContainer
          navigate={navHandler}
          ship={data && data.ship.name}
          dates={data && data.embark}
          duration={data && data.cruise.nights}
          price={data && data.pricing[0].prices[0].standard}
        />
        <div className="row">
          <div className="col text-block">
            {data.cruise.descriptions
              ?.filter((obj) => obj.title === 'Title')
              .map((item, index) => (
                <TextBlock key={index} title={item.title} text={item.description} />
              ))}
            {data &&
              data.ship.descriptions.map((item, index) => (
                <TextBlock key={index} title={item.title} text={item.description} />
              ))}

            {data &&
              data.cruise.itinerary.map((item, index) => (
                <TextBlock
                  title={`Day ${item.day} - ${item.port}, ${codeToName(
                    item.country.toUpperCase()
                  )}`}
                  text={`Port - ${item.port},  Country - ${codeToName(
                    item.country.toUpperCase()
                  )}, Arrive Time - ${item.arrive_time || 'N/A'}, Depart Time - ${
                    item.depart_time || 'N/A'
                  }`}
                  key={index}
                />
              ))}
          </div>
          <div className="col">
            <Image src={data.ship.images[1]} />

            <GrayBlock text="MAP IMAGE" />
          </div>
        </div>
        <PricingContentPage setCabineData={setCabineData} data={data} />
      </div>
    </>
  ) : (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vw',
        position: 'fixed'
      }}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export { PricingPage };
