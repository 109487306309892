import React, { useEffect, useMemo, useState } from 'react';
import { Segmented } from 'antd';
import StateroomTypes from './StateroomTypes';
import StateroomTable from './StateroomTable';
import getCheapestStandardTypeCabin from '../../helpers/getCheapestStandardTypeCabin';
import useStore from '../../store/userStore';

const Staterooms = ({ markets, cabins, cabinData, setCabineData }) => {
  const { setPricingData, setTypeCabin } = useStore();
  const [category, setCategory] = useState(getCheapestStandardTypeCabin(markets));

  useEffect(() => {
    console.log(cabins);
    setTypeCabin(category);
    setPricingData(markets[category].price); // Cheapest price of type cabin
  }, [category]);

  // prepare options categories for Segmented
  const categories = useMemo(() => {
    const orderedMarkets = ['inside', 'outside', 'balcony', 'suite'];
    const opt = [];

    orderedMarkets.forEach((marketKey) => {
      const market = markets[marketKey];
      const price = parseInt(market.price) ? parseInt(market.price) / 2 : null;

      opt.push({
        label: <StateroomTypes type={marketKey} price={price ? `$${price}` : 'N/A'} />,
        value: marketKey,
        disabled: !price
      });
    });

    return opt;
  }, [markets]);

  // cabins with prices
  const availableCabins = useMemo(
    () => cabins[category].grades.filter((cabin) => cabin.prices),
    [category]
  );

  // sort
  if (availableCabins.length > 1) {
    availableCabins.sort((a, b) => {
      const priceA = a.prices?.standard !== undefined ? parseFloat(a.prices.standard) : Infinity;
      const priceB = b.prices?.standard !== undefined ? parseFloat(b.prices.standard) : Infinity;

      return priceA - priceB;
    });
  }

  const handleChangeTypeCabin = (value) => {
    setCategory(value);
  };

  return (
    <>
      {categories && (
        <div className={'mb-4'}>
          <Segmented
            block
            options={categories}
            value={category}
            onChange={handleChangeTypeCabin}
            size={'small'}
          />
        </div>
      )}

      <StateroomTable
        cabins={availableCabins}
        cabinData={cabinData}
        setCabineData={setCabineData}
      />
    </>
  );
};

export default Staterooms;
