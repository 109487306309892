import { getOptions } from '../helpers/getOptions';
import { apiSearch } from './apiSearch';

const searchAPI = apiSearch.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({
        session,
        language = getOptions('react_app_cabin_select_api_language'),
        source,
        start_date,
        end_date,
        nights,
        ship,
        tags,
        country
      }) => {
        if (!session) {
          return;
        }

        const params = new URLSearchParams();
        params.append('session', session);
        if (language) params.append('language', language);
        if (source) params.append('source', source);
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);

        if (Array.isArray(nights)) {
          nights.forEach((night) => params.append('night', night));
        } else if (nights) {
          const nightValues = nights.split(',').map((night) => night.trim());
          nightValues.forEach((night) => params.append('night', night));
        }

        if (Array.isArray(ship)) ship.forEach((s) => params.append('ship', s));
        else if (ship) params.append('ship', ship);

        if (Array.isArray(tags)) tags.forEach((tag) => params.append('tags', tag));
        else if (tags) params.append('tags', tags);

        if (Array.isArray(country)) country.forEach((c) => params.append('country', c));
        else if (country) params.append('country', country);

        return { url: `/search/?${params.toString()}`, method: 'GET' };
      }
    }),
    cruiseDetail: builder.query({
      query: ({
        sailing_code,
        session,
        source,
        language = getOptions('react_app_cabin_select_api_language')
      }) => {
        if (!session) {
          return;
        }

        return {
          url: `/search/${sailing_code}/`,
          params: {
            session,
            source,
            language
          },
          method: 'GET'
        };
      },
      providesTags: ['CruiseDetail']
    }),
    getCabins: builder.query({
      query: ({
        session,
        source,
        rate_code,
        grade_code,
        guest_count,
        sailing_code = 'VTWO1220230803',
        language = getOptions('react_app_cabin_select_api_language'),
        guest_dob_1 = '1979-04-22',
        guest_dob_2 = '1979-04-22',
        guest_dob_3 = '1979-04-22'
      }) => {
        if (!session) throw new Error('Session is required');

        return {
          url: `/cabins/${sailing_code}/`,
          params: {
            session,
            source,
            rate_code,
            grade_code,
            guest_count,
            language,
            guest_dob_1,
            guest_dob_2,
            guest_dob_3
          },
          method: 'GET'
        };
      }
    })
  }),
  overrideExisting: true
});

export const { useSearchQuery, useCruiseDetailQuery, useLazyGetCabinsQuery, useGetCabinsQuery } =
  searchAPI;
