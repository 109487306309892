import React from 'react';
import styles from './StateroomTableInput.module.css';
import { Typography } from 'antd';
import useStore from '../../../../store/userStore';
import { showCurrency } from '../../../../helpers/showCurrency';
const { Text, Paragraph } = Typography;

const StateroomTableInput = ({
  i,
  value,
  selectedValue,
  price,
  onChangeCallback,
  caption = null
}) => {
  const { currency } = useStore();

  return (
    <>
      <label htmlFor={`radio-${i}`} className={styles.label}></label>
      <input
        className={styles.input}
        id={`radio-${i}`}
        type={'radio'}
        name={'cabin'}
        onChange={onChangeCallback}
        value={value}
        checked={selectedValue === value}
      />
      <div className={styles.bg}></div>
      <div className={styles.content}>
        <Text className={'fs-3'}>{showCurrency(currency, price, true, true)}</Text>
        <Paragraph className={'p-0'}>{caption || 'Price per Person'}</Paragraph>
      </div>
    </>
  );
};

export default React.memo(StateroomTableInput);
