import React from 'react';
import { Image } from 'antd';

export default function ImageCards({ img, ...props }) {
  const images = Array.isArray(img) ? img : [img];
  const shouldApplyBlur = Array.isArray(img);
  return (
    <>
      {images.map((image, index) => (
        <Image
          key={index}
          src={image}
          style={{ borderRadius: 0, filter: shouldApplyBlur ? 'blur(5px)' : 'none' }}
          {...props}
        />
      ))}
    </>
  );
}
