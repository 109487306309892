import React from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Card, Image } from 'antd';
// TODO: mockup data, remove and use img from server
import mapImg from '../../map.png';
import { fallback } from '../../helpers/fallback';
const MapCard = (img) => {
  const images = img ? img.img : mapImg;
  return (
    <Card className={'position-relative shadow'}>
      <Image
        width={'100%'}
        src={images}
        preview={{
          src: images
        }}
        fallback={fallback()}
      />
      <FullscreenIcon className={'position-absolute'} style={{ top: '5px', right: '5px' }} />
    </Card>
  );
};

export default React.memo(MapCard);
