function getCheapestStandardGrade(gradesArray) {
  if (gradesArray.length) {
    const cheapestGrade = gradesArray.reduce((cheapestGrade, currentGrate) => {
      if (!cheapestGrade && currentGrate?.prices) {
        return currentGrate;
      }

      if (cheapestGrade && !currentGrate?.prices) {
        return cheapestGrade;
      }

      if (cheapestGrade && currentGrate?.prices) {
        const minPrice = parseFloat(cheapestGrade.prices.standard);
        const currentPrice = parseFloat(currentGrate.prices.standard);

        return currentPrice < minPrice ? currentGrate : cheapestGrade;
      }

      return null;
    }, null);

    return cheapestGrade;
  } else {
    return null;
  }
}

export default getCheapestStandardGrade;
