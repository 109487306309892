function removeTrailingBrTags(str) {
  if (!str) {
    return '';
  }

  // remove </br> </ br> <br> <br/> <br /> in the end of the string
  const regex = /(<\/?\s*br\s*\/?>\s*)+$/g;
  return str.replace(regex, '');
}

export default removeTrailingBrTags;
