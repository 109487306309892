/* eslint-disable no-unused-vars */
export function extractCabins(maternalObjects, additionalObjects) {
  const readyObjects = [];

  for (const maternalObject of maternalObjects) {
    const { prices, ...otherKeys } = maternalObject;

    const cabins = prices.map((price) => {
      const { price: priceObject, ...priceObjectKeys } = price;

      return {
        ...priceObjectKeys,
        ...otherKeys
      };
    });

    readyObjects.push(...cabins);
  }

  const mergedObjects = readyObjects.map((readyObject) => {
    const { grade, ...otherKeys } = readyObject;

    const additionalObject = additionalObjects.find((obj) => obj.code === grade);

    if (additionalObject) {
      return {
        ...readyObject,
        ...additionalObject,
        grade,
        ...otherKeys
      };
    }

    return readyObject;
  });

  const uniqueObjects = [];
  const seenGrades = new Set();

  for (const mergedObject of mergedObjects) {
    const { grade, price, ...otherKeys } = mergedObject;

    if (!seenGrades.has(grade)) {
      seenGrades.add(grade);
      uniqueObjects.push(mergedObject);
    } else {
      const existingObject = uniqueObjects.find((obj) => obj.grade === grade && obj.price < price);

      if (existingObject) {
        existingObject.price = price;
        Object.assign(existingObject, otherKeys);
      }
    }
  }

  return uniqueObjects;
}
