import React from 'react';
import { List, Typography } from 'antd';
import useStore from '../../store/userStore';
const { Text } = Typography;
const { Item } = List;

const StateroomInfo = () => {
  const { typeCabin, guests } = useStore();
  const dataStateroom = [typeCabin + ' for ' + guests + ' Adults'];

  return (
    <>
      <Text strong className={'fs-5 text-uppercase'}>
        Stateroom
      </Text>
      <List
        size="small"
        className={'border-0'}
        dataSource={dataStateroom}
        renderItem={(item, index) => (
          <Item className={'border-0 ps-0'}>
            <Text style={{ textTransform: 'capitalize' }}>
              {index + 1} - {item}
            </Text>
          </Item>
        )}
      />
    </>
  );
};

export default StateroomInfo;
