import React from 'react';
import useStore from '../../store/userStore';
const ButtonPrimary = ({ onClick, children, className, ...rest }) => {
  const { options: fetchOptions } = useStore();
  return (
    <button
      className={`button ${className}`}
      onClick={() => {
        onClick();
      }}
      {...rest}
      style={{ backgroundColor: fetchOptions.search_app_color }}>
      {children}
    </button>
  );
};

export default ButtonPrimary;
