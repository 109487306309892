const formatParamsArray = (data, key) => {
  const items = {};
  let i = 1;
  for (const value of data) {
    items[`${key}_${i++}`] = value;
  }

  return items;
};

export default formatParamsArray;
