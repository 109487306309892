import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Image, Typography } from 'antd';
import CabinPlaceholder from '../../Icons/CabinPlaceholder';
import StateroomTableInput from './StateroomTableInput';
import '../../searchComponent/new-design.css';
import { fallback } from '../../../helpers/fallback';

const { Text } = Typography;

function getThirdPrice(standardPrice, thirdPrice) {
  const standard = +standardPrice;
  const third = +thirdPrice;
  return (standard + third) / 3;
}

function getFourthPrice(standardPrice, thirdPrice, fourthPrice) {
  const standard = +standardPrice;
  const third = +thirdPrice;
  const fourth = +fourthPrice;
  return (standard + third + fourth) / 4;
}

function generateTable(data, selectedCabin, handleSelect, isMobile) {
  const arrayData = [];
  const keys = [];

  data.forEach((item, i) => {
    const { code, prices } = item;

    if (prices) {
      let standard = +prices.standard ? prices.standard / 2 : null;
      let third = +prices.third ? getThirdPrice(prices.standard, prices.third) : null;
      let fourth =
        +prices.third && +prices.fourth
          ? getFourthPrice(prices.standard, prices.third, prices.fourth)
          : null;
      let single = +prices.single ? prices.single : null;
      const { description } = item.descriptions[0];

      keys.push(i);

      arrayData.push({
        key: i,
        picture: item.images ? (
          <Image
            src={item.images[0]}
            width={isMobile ? '150px' : '210px'}
            height={isMobile ? '85px' : '120px'}
            style={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
            alt="alt"
            fallback={fallback()}
            placeholder
          />
        ) : (
          <CabinPlaceholder />
        ),
        standard: standard ? (
          <StateroomTableInput
            i={code}
            price={standard}
            value={JSON.stringify({ code, price: standard, passenger: 2 })}
            selectedValue={JSON.stringify(selectedCabin)}
            onChangeCallback={handleSelect}
          />
        ) : (
          <Text className={'fs-4'}>N/A</Text>
        ),
        third: third ? (
          <StateroomTableInput
            i={i}
            price={third}
            value={JSON.stringify({ code, price: third, passenger: 3 })}
            selectedValue={JSON.stringify(selectedCabin)}
            onChangeCallback={handleSelect}
          />
        ) : (
          <Text className={'fs-4'}>N/A</Text>
        ),
        fourth: fourth ? (
          <StateroomTableInput
            i={i}
            price={fourth}
            value={JSON.stringify({ code, price: fourth, passenger: 4 })}
            selectedValue={JSON.stringify(selectedCabin)}
            onChangeCallback={handleSelect}
          />
        ) : (
          <Text className={'fs-4'}>N/A</Text>
        ),

        single: single ? (
          <StateroomTableInput
            i={i}
            price={single}
            value={JSON.stringify({ code, price: standard, passenger: 1 })}
            selectedValue={JSON.stringify(selectedCabin)}
            onChangeCallback={handleSelect}
            caption={'*Incl. Discount'}
          />
        ) : (
          <Text className={'fs-4'}>N/A</Text>
        ),
        description: (
          <>
            {description && description}
            <span className={'fs-6 d-block'}>
              {/* {item && <strong>Deck:</strong>} */}
              {/* {item && item?.decks[0]?.code} */}
            </span>
          </>
        )
      });
    }
  });

  return [arrayData, keys];
}

function useStateroomTable(cabins, selectedCabin, handleSelect) {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  const columns = [
    {
      title: <Text className={'fs-4'}>Pricing per guest</Text>,
      width: isMobile ? 170 : 230,
      dataIndex: 'picture',
      key: 'picture',
      fixed: 'left'
    },
    {
      title: (
        <>
          <Text className={'fs-3'}>2 Guests</Text>
          <div>Price per person</div>
        </>
      ),
      dataIndex: 'standard',
      key: 'standard'
    },
    {
      title: (
        <>
          <Text className={'fs-3'}>3 Guests</Text>
          <div>Price per person</div>
        </>
      ),
      dataIndex: 'third',
      key: 'third'
    },
    {
      title: (
        <>
          <Text className={'fs-3'}>4 Guests</Text>
          <div>Price per person</div>
        </>
      ),
      dataIndex: 'fourth',
      key: 'fourth'
    },
    {
      title: (
        <>
          <Text className={'fs-3'}>1 Guest</Text>
          <div>*Incl. Discount</div>
        </>
      ),
      dataIndex: 'single',
      key: 'single'
    }
  ];

  const [dataTable, keys] = generateTable(cabins, selectedCabin, handleSelect, isMobile);

  return {
    columns,
    dataTable,
    keys
  };
}

export default useStateroomTable;
