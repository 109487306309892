/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
  useCruiseDetailQuery,
  useLazyGetCabinsQuery,
  useGetCabinsQuery
} from '../../services/SearchService';
import { parseQueryString } from '../../helpers/parseQueryString';
import useAuth from '../../hooks/useSession';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormContinue } from './FormContinue';
import { ShipBaner } from './ShipBaner';
import { GuestChoosing } from './GuestChoosing';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { NoContent } from './NoContent';
import { filterObjectsByKeyValue } from '../../helpers/filterObjectsByKeyValue';
import { StateroomsTypeChoosing } from './StateroomsTypeChoosing';
import { Row, Col, Space, Checkbox, Button, Empty } from 'antd';
import TwoColumnLayout from '../../components/TwoColumnLayout';
import MapCard from '../../components/MapCard';
import PricingContentPage from '../pricing&content/PricingContentPage';
import useStore from '../../store/userStore';
import Staterooms from '../../components/Staterooms';
import getCheapestStandardGrade from '../../helpers/getCheapestStandardGrade';
import { giveCabinsData } from '../../helpers/giveCabinsData';
import SidebarCard from '../../components/SidebarCard';
import SummaryInfo from '../../components/SummaryInfo';
import { Spin } from 'antd';
import { getOptions } from '../../helpers/getOptions';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
const Stateroom = () => {
  const TABLE_VIEW = getOptions('react_app_cabin_select_table_view');
  const location = useLocation();
  const navigation = useNavigate();
  const [cabineData, setCabineData] = useState({});
  const { sessionKey, isLoading: isLoadingSession } = useAuth();
  const {
    setShipName,
    setEmbark,
    setNights,
    setItinerary,
    stageStateroomsSelect,
    guests
    // setStageStateroomsSelect
  } = useStore();
  const [stepSelection, setStepSelection] = useState(2);

  // request cruise data
  const { data, isLoading } = useCruiseDetailQuery({
    session: sessionKey,
    sailing_code: parseQueryString(location).code,
    source: parseQueryString(location).source
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, stepSelection]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        (sessionKey === null && !isLoadingSession) ||
        (sessionKey === undefined && !isLoadingSession)
      ) {
        window.location.reload();
      }
    }, 2500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const pricing = data.pricing;
      // console.log('pricing - ', pricing, 'code - ', data.code);
      setCabineData({
        code: data.code,
        ship: data.code,
        decks: data.ship.decks,
        rate_code: data.pricing[0]?.rate_code,
        passenger: 1,
        source: data.source
      });
      setShipName(data?.ship?.name);
      setEmbark(data?.embark);
      setNights(data?.cruise?.nights);
      setItinerary(data?.cruise?.itinerary);
      setStepSelection(stageStateroomsSelect);
    }
  }, [data]);

  //for Breadcrumbs
  const path = ['Home', 'Result', 'Staterooms'];

  const grades = data && data.ship.grades;
  const cheapestPricing =
    data &&
    data?.pricing.reduce((cheapest, current) => {
      if (
        !cheapest ||
        parseFloat(current.prices.single) < parseFloat(cheapest.prices.single) ||
        parseFloat(current.prices.standard) < parseFloat(cheapest.prices.standard) ||
        parseFloat(current.prices.third) < parseFloat(cheapest.prices.third)
      ) {
        return current;
      }
      return cheapest;
    }, null);

  console.log(cheapestPricing);
  const gradesWithPrice =
    data &&
    grades.map((grade) => ({
      ...grade,
      prices: cheapestPricing?.prices[grade.code] || null
    }));

  const gradesWithPriceForTable =
    grades &&
    grades.reduce((result, grade) => {
      const prices = data?.pricing[0]?.prices.find((price) => grade.code === price.grade);
      result.push({ ...grade, prices: prices || null });
      return result;
    }, []);

  console.log('gradesWithPrice', gradesWithPrice);
  const cabinTypes = data && Object.keys(data.markets);
  const cabinTypesObjWithGrades =
    cabinTypes &&
    gradesWithPrice &&
    cabinTypes.reduce((resultObj, type) => {
      const grades = gradesWithPrice.filter((grade) => type === grade['meta_category']);
      resultObj[type] = {
        lowestGrade: getCheapestStandardGrade(grades),
        grades
      };

      return resultObj;
    }, {});

  const cabinTypesObjWithGradesForTable =
    cabinTypes &&
    gradesWithPriceForTable &&
    cabinTypes.reduce((resultObj, type) => {
      const grades = gradesWithPriceForTable.filter((grade) => type === grade['meta_category']);
      resultObj[type] = {
        lowestGrade: getCheapestStandardGrade(grades),
        grades
      };

      return resultObj;
    }, {});

  // const cabinTypesObjWithGrades2 =
  //   cabinTypes &&
  //   gradesWithPrice &&
  //   cabinTypes.reduce((resultObj, type) => {
  //     const grades = gradesWithPrice.filter((grade) => type === grade['meta_category']);

  //     resultObj[type] = grades;
  //     return resultObj;
  //   }, {});

  useEffect(() => {
    if (cabinTypesObjWithGrades && stepSelection == 2) {
      if (stageStateroomsSelect !== 4) setStepSelection(3);
    }
  }, [cabinTypesObjWithGrades]);

  const typeGuestInside = ['single', 'standard', 'third', 'fourth', 'extra'];

  const allCabins = giveCabinsData(data && data);

  // const cabinsTypeArray =
  //   cabinTypes &&
  //   cabinTypes.map((item) => filterObjectsByKeyValue(allCabins, 'meta_category', item));

  // const [selectedTypeCabin, setSelectedTypeCabin] = useState(null);

  function findAvailableCabins(arr, value) {
    if (arr) {
      return arr.filter((obj) => obj[value] !== '0.00');
    }
  }
  // const filteredCabins = typeGuestInside.reduce((result, cabinType) => {
  //   result[cabinType] = findAvailableCabins(allCabins, cabinType);
  //   return result;
  // }, {});

  const goToChooseCabin = () => {
    navigation(
      `/cabin?sourse=${data.source}&rate_code=${data.pricing[0].rate_code}&grade_code=${
        cabineData.code
      }&guest_count=${1}&ship=${parseQueryString(location).code}`,
      {
        state: cabineData
      }
    );
  };
  const setStepSelectionHandler = () => {
    if (stepSelection !== 4) {
      setStepSelection((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="container-xxl new-design">
        <Breadcrumbs paths={path} />
      </div>

      <TwoColumnLayout
        firstColumnContent={
          TABLE_VIEW === 'false' || stepSelection >= 4 ? (
            <Space size={[30, 30]} direction={'vertical'} className={'w-100'}>
              {stepSelection <= 3 && stepSelection >= 3 && guests >= 1 && (
                <ShipBaner
                  shipImage={
                    data?.cruise?.images.length >= 1
                      ? data?.cruise?.images[0]
                      : data?.ship?.images[0]
                  }
                  shipName={data?.cruise?.name}
                />
              )}
              {stepSelection == 1 && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Spin />
                </div>
              )}
              {stepSelection >= 2 && stepSelection <= 3 && data?.pricing && (
                <GuestChoosing cabins={data && data?.pricing} />
              )}
              {stepSelection >= 3 && stepSelection <= 3 && guests >= 1 && (
                <StateroomsTypeChoosing
                  allPrices={data && data?.pricing}
                  grades={data && data?.ship?.grades}
                />
              )}
              {stepSelection >= 4 && (
                <>
                  <PricingContentPage
                    goBack={setStepSelection}
                    data={data}
                    setCabineData={setCabineData}
                    allCabins={allCabins}
                    allPrices={data && data?.pricing}
                    grades={data && data?.ship?.grades}
                  />
                </>
              )}
            </Space>
          ) : (
            /*TODO: another view of search results - cabins table*/
            data?.markets &&
            cabinTypesObjWithGrades && (
              <div className="container-xxl mb-4">
                <Staterooms
                  markets={data.markets}
                  cabins={cabinTypesObjWithGradesForTable}
                  cabinData={cabineData}
                  setCabineData={setCabineData}
                />
              </div>
            )
          )
        }
        secondColumnContent={
          <Row gutter={[30, 30]}>
            <Col flex="1 1 300px">
              <MapCard img={data && data?.cruise?.map} />
            </Col>
            <Col flex="1 1 300px">
              <SidebarCard title={'Itinerary'}>
                <SummaryInfo dateInfo={true} portsInfo={true} stateroomInfo={true} />
              </SidebarCard>
            </Col>
          </Row>
        }
      />

      <FormContinue
        nextStep={setStepSelectionHandler}
        step={TABLE_VIEW === 'ture' ? 4 : stepSelection}
        goToChooseCabin={goToChooseCabin}
        allCabins={allCabins && allCabins}
        tableView={TABLE_VIEW}
      />
    </>
  );
};

export default Stateroom;
