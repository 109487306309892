export const formatObjData = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime()) || !date || date == undefined) {
    // console.warn('Invalid date');
    return '2000-12-12';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
