/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, Segmented, Typography, Col, Row, Switch } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import UserInputIcon from './Icons/UserInputIcon';
import ArrowDownSelectIcon from './Icons/ArrowDownSelectIcon';
import DateInputIcon from './Icons/DateInputIcon';
import MarkerInputIcon from './Icons/MarkerInputIcon';
import countryList from '../countries.data';
import userStore from '../store/userStore';
import { showCurrency } from '../helpers/showCurrency';
import { getOptions } from '../helpers/getOptions';
import { useLocation } from 'react-router-dom';
import { useCreateOpaquePaymentMutation } from '../services/opaquePaymentApi';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
const { Paragraph, Title, Text } = Typography;

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM';
const monthFormat = 'MM';
const yearFormat = 'YYYY';

const PaymentComponent = ({ setPaymentForm, passengersData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://jstest.authorize.net/v1/Accept.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [create_opaqueData, { data: opaqueData_req }] = useCreateOpaquePaymentMutation();

  const {
    pricingData,
    setPricingData,
    setBillingData,
    setPaymentType,
    currency,
    PNR,
    allPriceData
  } = userStore();

  const [typePrice, setTypePrice] = useState(null);
  const [typePriceOptions, setTypePriceOptions] = useState(null);
  const fullPrice = pricingData;
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const [showForm, setShowFrom] = useState(false);

  const setShowFromHandler = () => {
    setShowFrom((prev) => !prev);
  };
  const [transaction, setTransaction] = useState([
    {
      currency: currency,
      amount: typePrice,
      transaction_type: 'creditcard',
      made_by: 'Website Authorize.net',
      tokenized: null
    }
  ]);
  useEffect(() => {
    setPaymentForm(form);
  }, []);

  const onFinish = (values) => {
    // save in store
    setBillingData(values);
    payHandler(values);
  };

  const payHandler = async (paymentData) => {
    const authData = {
      apiLoginID: getOptions('react_app_cabin_select_authorize_api_login_id'),
      clientKey: getOptions('react_app_cabin_select_authorize_client_key')
    };

    const cardData = {
      cardNumber: paymentData.cardNumber,
      month: paymentData.expirationDate.format(monthFormat),
      year: paymentData.expirationDate.format(yearFormat),
      cardCode: paymentData.ccv
    };
    const transactionRequest = {
      transactionType: 'authCaptureTransaction',
      amount: typePrice,
      description: 'Test payment',
      billTo: {
        firstName: paymentData.firstName,
        lastName: paymentData.surname,
        city: paymentData.city,
        state: paymentData.state,
        zip: paymentData.zipCode,
        country: paymentData.country
      },
      refId: PNR
    };

    const secureData = {
      authData: authData,
      cardData: cardData,
      transactionRequest: transactionRequest
    };
    window.Accept.dispatchData(secureData, responseHandler);
    // navigation('/booking-confirmation');
  };

  const responseHandler = (response) => {
    if (response.messages.resultCode === 'Error') {
      const errorMessage = response.messages.message[0].text;
      console.error(errorMessage);
    } else {
      submitHandler(response.opaqueData);
      console.error(response);
    }
  };

  const submitHandler = async (opaqueData) => {
    setTransaction([
      {
        currency: currency,
        amount: typePrice,
        transaction_type: 'creditcard',
        made_by: 'Website Authorize.net',
        tokenized: opaqueData.dataValue
      }
    ]);

    create_opaqueDataHandler();
  };

  const PriceType = ({ caption, price }) => {
    return (
      <div className={'p-2 flex-grow-1'}>
        <Paragraph className={`fs-5 mb-0`}>
          <span className={'text-uppercase'}>{caption}</span>
        </Paragraph>

        <Text className={`fs-2 fw-bold`}>{price}</Text>
      </div>
    );
  };

  useEffect(() => {
    setTypePrice(fullPrice);

    const deposit = allPriceData ? allPriceData?.payment_schedule[0]?.amount : 0;
    const formatD = showCurrency(currency, deposit, true);
    const formatFullPrice = showCurrency(currency, fullPrice, true);
    const options = [
      {
        label: <PriceType caption={'Full amount'} price={formatFullPrice} />,
        value: fullPrice
      },
      {
        label: <PriceType caption={'Deposit payment'} price={formatD} />,
        value: deposit
      }
    ];
    setTypePriceOptions(options);

    setTransaction({
      currency: currency,
      amount: typePrice,
      transaction_type: 'creditcard',
      made_by: 'Website Authorize.net',
      tokenized: null
    });
  }, [fullPrice]);

  const validateCardNumber = (rule, value, callback) => {
    const regex = /^[0-9]{16}$/;

    if (!value) {
      return Promise.reject('Please enter card number');
    }

    if (!regex.test(value)) {
      return Promise.reject('Invalid card number');
    }

    return Promise.resolve();
  };

  const validateCVC = (rule, value, callback) => {
    const regex = /^[0-9]{3}$/;

    if (!value) {
      return Promise.reject('Please enter CVC');
    }

    if (!regex.test(value)) {
      return Promise.reject('Please input a valid CVC');
    }

    return Promise.resolve();
  };

  const handleChangeTypePrice = (value) => {
    setTypePrice(value);
    if (value === fullPrice) {
      setPricingData(value);
      setPaymentType('full');
    } else {
      setPaymentType('deposit');
    }
  };

  const create_opaqueDataHandler = async () => {
    const req = await create_opaqueData({
      session: Cookies.get('sessionKey'),
      pnr: PNR,
      source: searchParams.get('source'),
      transactions: transaction
    }).then(navigate('/booking-confirmation'));
  };

  return (
    <>
      <Title level={2} className={'fs-2 fw-normal'}>
        Payment Information
      </Title>

      {!pricingData ? (
        <p>Loading...</p>
      ) : (
        pricingData &&
        typePriceOptions && (
          <Segmented
            block
            options={typePriceOptions}
            value={typePrice}
            onChange={handleChangeTypePrice}
            size={'small'}
            className={'mb-5'}
          />
        )
      )}

      <Form
        form={form}
        name="payment"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="cardNumber"
              label={<div className="customFieldLabel">Card number</div>}
              rules={[
                {
                  required: true,
                  validator: validateCardNumber
                }
              ]}>
              <Input
                bordered={false}
                className="input"
                placeholder="Enter 16 digits"
                maxLength={16}
                prefix={<UserInputIcon />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className="customFieldSelect">
              <DateInputIcon />
              <Form.Item
                name="expirationDate"
                label={<div className="customFieldLabel">Expiry Date</div>}
                rules={[{ required: true, message: 'Please select expiration date' }]}
                className="customFieldSelectItem">
                <DatePicker.MonthPicker
                  format={dateFormat}
                  style={{ fontSize: '1rem !important' }}
                  suffixIcon={false}
                  bordered={false}
                  className="customFieldSelectInput"
                  placeholder={dateFormat}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              style={{ maxWidth: '155px' }}
              name="ccv"
              label={<div className="customFieldLabel">Card Code</div>}
              rules={[{ required: true, validator: validateCVC }]}>
              <Input
                bordered={false}
                className="input"
                placeholder="3 digits"
                maxLength={3}
                prefix={<UserInputIcon />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Title level={2} className={'fs-2 fw-bold mt-4'}>
          Billing address:
        </Title>
        <p>Same as lead passenger?</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ marginRight: 26 }}>Yes</p>
          <Switch defaultChecked onChange={setShowFromHandler} />
        </div>

        {showForm ? (
          <div>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  label={<div className="customFieldLabel">First Name</div>}
                  rules={[{ required: true, message: 'Please enter your first name' }]}>
                  <Input
                    defaultValue={passengersData[0].firstName}
                    bordered={false}
                    className="input"
                    placeholder="John"
                    prefix={<UserInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="surname"
                  label={<div className="customFieldLabel">Surname</div>}
                  rules={[{ required: true, message: 'Please enter your surname' }]}>
                  <Input
                    defaultValue={passengersData[0].surname}
                    bordered={false}
                    className="input"
                    placeholder="Smith"
                    prefix={<UserInputIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="city"
                  label={<div className="customFieldLabel">City</div>}
                  rules={[{ required: true, message: 'Please enter your city' }]}>
                  <Input
                    defaultValue={passengersData[0].city}
                    bordered={false}
                    className="input"
                    placeholder="Boston"
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="state"
                  label={<div className="customFieldLabel">State</div>}
                  rules={[{ required: true, message: 'Please enter your state' }]}>
                  <Input
                    defaultValue={passengersData[0].state}
                    bordered={false}
                    className="input"
                    placeholder="State"
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="zipCode"
                  label={<div className="customFieldLabel">Zip Code</div>}
                  rules={[{ required: true, message: 'Please input zip code!' }]}>
                  <Input
                    defaultValue={passengersData[0].zipCode}
                    bordered={false}
                    className="input"
                    placeholder="Zip Code"
                    prefix={<MarkerInputIcon />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <div className="customFieldSelect">
                  <MarkerInputIcon />
                  <Form.Item
                    name="country"
                    label={<div className="customFieldLabel">Country</div>}
                    rules={[{ required: true, message: 'Please select your country' }]}
                    className={'customFieldSelectItem'}>
                    <Select
                      showSearch
                      defaultValue={passengersData[0].country}
                      placeholder="Select a country"
                      bordered={false}
                      className={'customFieldSelectInput'}
                      suffixIcon={<ArrowDownSelectIcon />}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={countryList}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};

export { PaymentComponent };
