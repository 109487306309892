import React from 'react';

const ArrowDownSelectIcon = (props) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.47017 0L7.217 4.74688L11.9742 0L13.4355 1.46138L7.217 7.68L0.998447 1.46138L2.47017 0Z"
      fill="black"
    />
  </svg>
);

export default ArrowDownSelectIcon;
