import { apiSearch } from './apiSearch';

export const holdCabinAPI = apiSearch.injectEndpoints({
  endpoints: (builder) => ({
    holdCabin: builder.mutation({
      query: ({ sailing_code, source, session, rate_code, grade_code, cabin_number }) => ({
        url: `https://devsearchapi.cabinselect.com/holdcabin/${sailing_code}/${source}/`,
        body: {
          session,
          sailing_code,
          rate_code,
          grade_code,
          cabin_number
        },
        method: 'POST'
      }),
      invalidatesTags: ['HoldCabin']
    })
  }),
  overrideExisting: true
});

export const { useHoldCabinMutation } = holdCabinAPI;
