import React from 'react';

const EmailInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M30.0781 3.70764C30.0781 2.05764 28.7281 0.707642 27.0781 0.707642H3.07812C1.42812 0.707642 0.078125 2.05764 0.078125 3.70764V21.7076C0.078125 23.3576 1.42812 24.7076 3.07812 24.7076H27.0781C28.7281 24.7076 30.0781 23.3576 30.0781 21.7076V3.70764ZM27.0781 3.70764L15.0781 11.2076L3.07812 3.70764H27.0781ZM27.0781 21.7076H3.07812V6.70764L15.0781 14.2076L27.0781 6.70764V21.7076Z"
        fill="white"
      />
    </svg>
  </div>
);

export default EmailInputIcon;
