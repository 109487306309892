import React from 'react';

const DateInputIcon = (props) => (
  <div className={'customFieldIcon'}>
    <svg
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M26.6667 30H3.33333V11.6667H26.6667M21.6667 0V3.33333H8.33333V0H5V3.33333H3.33333C1.48333 3.33333 0 4.81667 0 6.66667V30C0 30.8841 0.351189 31.7319 0.976311 32.357C1.60143 32.9821 2.44928 33.3333 3.33333 33.3333H26.6667C27.5507 33.3333 28.3986 32.9821 29.0237 32.357C29.6488 31.7319 30 30.8841 30 30V6.66667C30 4.81667 28.5 3.33333 26.6667 3.33333H25V0M23.3333 18.3333H15V26.6667H23.3333V18.3333Z"
        fill="white"
      />
    </svg>
  </div>
);

export default DateInputIcon;
